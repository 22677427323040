import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {SETTINGS} from '../setting/commons.settings';
import {Observable} from 'rxjs/Observable';
import {LoginService} from '../service/authentication/login.service';

@Injectable()
export class CheckoutGuard implements CanActivate {

	constructor(private router: Router,
				private loginService: LoginService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.loginService.isUserLoggedIn()) {
			this.loginService.isRedirectToCheckoutScreen = true;
			this.router.navigate([SETTINGS.PAGES.login]);
			return false;
		}
		this.loginService.isRedirectToCheckoutScreen = false;
		return true;
	}

}
