export class Constants {

	public static status = {
		ACT: 'Active',
		INA: 'Inactive'
	};

	public static statusConst = {
		ACT: 'ACT',
		INA: 'INA'
	};

	public static gender = {
		M: 'Male',
		F: 'Female'
	};

	public static title = {
		MR: 'Mr',
		MRS: 'Mrs',
		MS: 'Ms',
		DR: 'Dr'
	};

	public static yesNo = {
		YES: 'Y',
		NO: 'N'
	};


	public static privilegeCodes = {
		IE_SETTINGS_CONFIGURATIONS_EDIT: 'IE.SETTINGS.CONFIGURATIONS.EDIT',
		IEXPRESS_SETTINGS_MERCHANT_ADD_EDIT: 'IEXPRESS.SETTINGS.MERCHANT.ADD.EDIT',
		IEXPRESS_SETTINGS_DRIVER_GROUP_ADD_EDIT: 'IEXPRESS.SETTINGS.DRIVER.GROUP.ADD.EDIT',
		IEXPRESS_SETTINGS_DRIVER_ADD_EDIT: 'IEXPRESS.SETTINGS.DRIVER.ADD.EDIT',
		IEXPRESS_SETTINGS_DRIVER_MANAGER_ADD_EDIT: 'IEXPRESS.SETTINGS.DRIVER.MANAGER.ADD.EDIT',
		IEXPRESS_SETTINGS_DELIVERY_ZONE_ADD_EDIT: 'IEXPRESS.SETTINGS.DELIVERY.ZONE.ADD.EDIT',
		IEXPRESS_SETTINGS_PRICING_TEMPLATE_ADD_EDIT: 'IEXPRESS.SETTINGS.PRICING.TEMPLATE.ADD.EDIT',
		IEXPRESS_SETTINGS_MERCHANT_USER_ADD_EDIT: 'IEXPRESS.SETTINGS.MERCHANT.USER.ADD.EDIT',
		IEXPRESS_SETTINGS_USER_ADD_EDIT: 'IEXPRESS.SETTINGS.USER.ADD.EDIT',
		IEXPRESS_SETTINGS_ROLE_ADD_EDIT: 'IEXPRESS.SETTINGS.ROLE.ADD.EDIT',
		IEXPRESS_CUSTORMER_ORDER_PLACE: 'IEXPRESS.CUSTORMER.ORDER.PLACE',
		IEXPRESS_SETTINGS_CUSTOMER_ADD_EDIT: 'IEXPRESS.SETTINGS.CUSTOMER.ADD.EDIT',
		IEXPRESS_ORDERS_EDIT_ORDERS: 'IEXPRESS.ORDERS.EDIT.ORDERS',
		IEXPRESS_SETTINGS_CUSTOMER_VIEW: 'IEXPRESS.SETTINGS.CUSTOMER.VIEW',
		IEXPRESS_SETTINGS_MERCHANT_VIEW: 'IEXPRESS.SETTINGS.MERCHANT.VIEW',
		IEXPRESS_SETTINGS_DELIVERY_ZONE_VIEW: 'IEXPRESS.SETTINGS.DELIVERY.ZONE.VIEW',
		IEXPRESS_SETTINGS_PRICING_TEMPLATE_VIEW: 'IEXPRESS.SETTINGS.PRICING.TEMPLATE.VIEW',
		IEXPRESS_SETTINGS_MERCHANT_USER_VIEW: 'IEXPRESS.SETTINGS.MERCHANT.USER.VIEW',
		IEXPRESS_SETTINGS_USER_VIEW: 'IEXPRESS.SETTINGS.USER.VIEW',
		IEXPRESS_SETTINGS_ROLE_VIEW: 'IEXPRESS.SETTINGS.ROLE.VIEW',
		IEXPRESS_SETTINGS_CONFIGURATIONS_VIEW: 'IEXPRESS.SETTINGS.CONFIGURATIONS.VIEW',
		IEXPRESS_SETTINGS_DRIVER_GROUP_VIEW: 'IEXPRESS.SETTINGS.DRIVER.GROUP.VIEW',
		IEXPRESS_SETTINGS_DRIVER_MANAGER_VIEW: 'IEXPRESS.SETTINGS.DRIVER.MANAGER.VIEW',
		IEXPRESS_SETTINGS_DRIVER_VIEW: 'IEXPRESS.SETTINGS.DRIVER.VIEW'


	};

	public static masterDataKey = {
		IE_PRICING_TEMPLATES: 'IE_PRICING_TEMPLATES_data',
		IE_BUSINESS_TYPES: 'IE_BUSINESS_TYPES_data',
		IE_PAYMENT_TYPES: 'IE_PAYMENT_TYPES_data',
		IE_DELIVERY_TYPES: 'IE_DELIVERY_TYPES_data',
		IE_SUB_BUSINESS_TYPES: 'IE_SUB_BUSINESS_TYPES'

	};

	public static systemParameterTypes = {
		B: 'Boolean',
		D: 'Decimal',
		S: 'String'
	};

	public static daysOfWeek = {
		Su: 'Sunday',
		Mo: 'Monday',
		Tu: 'Tuesday',
		We: 'Wednesday',
		Th: 'Thursday',
		Fr: 'Friday',
		Sa: 'Saturday'
	};

	public static orderStatus = {
		PENDING: 'Pending',
		CARD_PAYMENT_PENDING: 'Card Payment Pending',
		RESTAURANT_ACCEPTED: 'Accepted by Restaurant',
		RESTAURANT_REJECTED: 'Rejected by Restaurant',
		DRIVER_ACCEPTED: 'Accepted by Driver',
		DRIVER_REJECTED: 'Rejected by Driver',
		DRIVER_PICKUP_COMPLETED: 'Pickup Completed By Driver',
		DRIVER_PICKUP_FAILED: 'Pickup Failed by Driver',
		DRIVER_DELIVERY_COMPLETED: 'Delivery Completed by Driver',
		DRIVER_DELIVERY_FAILED: 'Delivery Failed by Driver',
		COMPLETED_BY_ADMIN: 'Completed',
		CANCELLED_BY_ADMIN: 'Cancelled',
	};

	public static orderStatusConst = {
		PENDING: 'PENDING',
		CARD_PAYMENT_PENDING: 'CARD_PAYMENT_PENDING',
		RESTAURANT_ACCEPTED: 'RESTAURANT_ACCEPTED',
		RESTAURANT_REJECTED: 'RESTAURANT_REJECTED',
		DRIVER_ACCEPTED: 'DRIVER_ACCEPTED',
		DRIVER_REJECTED: 'DRIVER_REJECTED',
		DRIVER_PICKUP_COMPLETED: 'DRIVER_PICKUP_COMPLETED',
		DRIVER_PICKUP_FAILED: 'DRIVER_PICKUP_FAILED',
		DRIVER_DELIVERY_COMPLETED: 'DRIVER_DELIVERY_COMPLETED',
		DRIVER_DELIVERY_FAILED: 'DRIVER_DELIVERY_FAILED',
		COMPLETED_BY_ADMIN: 'COMPLETED_BY_ADMIN',
		CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
	};

	public static orderStatusColor = {
		PENDING: 'rgb(242, 0, 88)',
		CARD_PAYMENT_PENDING: '#D81B60',
		RESTAURANT_ACCEPTED: '#00897B',
		RESTAURANT_REJECTED: '#616161',
		DRIVER_ACCEPTED: '#1E88E5',
		DRIVER_REJECTED: '#B71C1C',
		DRIVER_PICKUP_COMPLETED: '#689F38',
		DRIVER_PICKUP_FAILED: '#B71C1C',
		DRIVER_DELIVERY_COMPLETED: '#33691E',
		DRIVER_DELIVERY_FAILED: '#B71C1C',
		COMPLETED_BY_ADMIN: '#1B5E20',
		CANCELLED_BY_ADMIN: '#5D4037',
	};

	public static salesChannelConst = {
		ADMIN: 'ADMIN',
		WEB: 'WEB',
		MOBILE: 'MOBILE',
	};

	public static salesChannel = {
		ADMIN: 'Admin',
		WEB: 'Web',
		MOBILE: 'Mobile',
	};

	public static userTypeConst = {
		ADMIN: 'ADMIN',
		CUSTOMER: 'CUSTOMER',
		MERCHANT: 'MERCHANT',
	};

	public static userType = {
		ADMIN: 'Admin',
		CUSTOMER: 'Customer',
		MERCHANT: 'Merchant',
	};

	public static systemParamKey = {
		IE_5: 'IE_5',
		IE_7: 'IE_7',
		IE_8: 'IE_8',
		IE_9: 'IE_9',
		IE_10: 'IE_10',
		IE_11: 'IE_11',
		IE_13: 'IE_13',
		IE_14: 'IE_14',
		IE_20: 'IE_20',
		IE_27: 'IE_27',
		IE_29: 'IE_29',
		IE_30: 'IE_30',
		IE_31: 'IE_31',
	};

	public static paymentTypesConst = {
		CASH: 'CASH',
		GENIE: 'GENIE',
		CARD_ONLINE: 'CARD_ONLINE',
		CARD_DELIVERY: 'CARD_DELIVERY',
		VOUCHER: 'VOUCHER',
	};

	public static paymentTypes = {
		CASH: 'Cash on Delivery',
		GENIE: 'Pay by Genie (Master/ Visa / Justpay)',
		CARD_ONLINE: 'Credit Card via Sampath Gateway',
		CARD_DELIVERY: 'Card on Delivery',
		VOUCHER: 'Voucher',
	};

	public static paymentTypeIcons = {
		CASH: 'cash-icon far fa-money-bill-alt',
		CARD_ONLINE: 'cash-icon far fa-credit-card',
		CARD_DELIVERY: 'cash-icon fab fa-cc-visa',
		GENIE: 'cash-icon fas fa-wallet',
	};

	public static amountSpecifiedAs = {
		PERCENTAGE: 'Percentage',
		VALUE: 'Value'
	};

	public static amountSpecifiedAsConst = {
		PERCENTAGE: 'PERCENTAGE',
		VALUE: 'VALUE'
	};

	public static deliveryTypeConst = {
		DELIVERY: 'DELIVERY',
		PICK_UP: 'PICK_UP'
	};

	public static referralStatusConst = {
		PENDING: 'PENDING',
		SUCCESS: 'SUCCESS',
	};

	public static voucherAmountSpecifiedAs = {
		PERCENTAGE: 'Percentage',
		VALUE: 'Value',
		CASH_BACK: 'Cash Back'
	};

	public static voucherAmountSpecifiedAsConst = {
		PERCENTAGE: 'PERCENTAGE',
		VALUE: 'VALUE',
		CASH_BACK: 'CASH_BACK'
	};

	public static referralLevel = {
		LEVEL_0: 'Level Zero',
		LEVEL_1: 'Level One',
		LEVEL_2: 'Level Two',
		LEVEL_3: 'Level Three',
		LEVEL_4: 'Level Four',
		LEVEL_5: 'Level Five',
	};

	public static referralLevelConst = {
		LEVEL_0: 'LEVEL_0',
		LEVEL_1: 'LEVEL_1',
		LEVEL_2: 'LEVEL_2',
		LEVEL_3: 'LEVEL_3',
		LEVEL_4: 'LEVEL_4',
		LEVEL_5: 'LEVEL_5',
	};
}

