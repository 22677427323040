import {Injectable} from '@angular/core';
import {LocalStorage} from "ngx-webstorage";
import {SETTINGS} from "../../setting/commons.settings";

@Injectable()
export class DataResetService {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ADDRESS_DATA)
	selectedAddressData;

	@LocalStorage(SETTINGS.STORAGE.SELECTED_MERCHANT_SEARCH_DATA)
	selectedMerchantSearchData;

	@LocalStorage(SETTINGS.STORAGE.SELECTED_MERCHANT_REQUEST)
	selectedMerchantRequest;

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_RECEIPT)
	orderReceiptData;

	@LocalStorage(SETTINGS.STORAGE.SHOW_MY_ORDERS)
	showMyOrders;

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_GENIE_INIT)
	genieInitResponseJson;

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	otpConfirmMobileNumber;

	@LocalStorage(SETTINGS.STORAGE.LOGIN_CONFIRMED_OTP)
	loginOTP;

	constructor() {
	}

	resetData() {
		this.selectedAddressData = null;
		this.selectedMerchantSearchData = null;
		this.selectedMerchantRequest = null;
		this.orderReceiptData = null;
		this.showMyOrders = null;
		this.otpConfirmMobileNumber = null;
		this.loginOTP = null;
	}

	resetNonOrderData() {
		this.orderReceiptData = null;
		this.showMyOrders = null;
		this.genieInitResponseJson = null;
	}

}
