import {Injectable} from '@angular/core';
import {DataService} from './data.service';

@Injectable()
export class StorageService {

  constructor(private dataService: DataService) {
  }

}
