import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Constants} from '../../../../main/setting/constants';
import {MoneyService} from '../../../../main/service/common/money.service';
import {AlertService} from '../../../../main/service/common/alert.service';
import {Router} from '@angular/router';
import {SETTINGS} from '../../../../main/setting/commons.settings';
import * as _ from 'underscore';
import {OrderService} from '../../../../main/service/order/order.service';

@Component({
	selector: 'kt-cart-wrapper',
	templateUrl: './cart-wrapper.component.html',
	styleUrls: ['./cart-wrapper.component.scss']
})
export class CartWrapperComponent implements OnInit, OnChanges, OnDestroy {

	@Input('page') page: string;
	@Input('isFinalStep') isFinalStep: boolean;
	@Input('isEligibleToPlaceOrder') isEligibleToPlaceOrder: boolean;
	@Input('customerOrder') customerOrder: any;
	@Input('voucherData') voucherData: any = {};
	@Input('promoData') promoData: any = {};

	@Output('onCheckoutOrder') onCheckoutOrder = new EventEmitter();
	@Output('onVoucherClear') onVoucherClear = new EventEmitter();
	@Output('onDiscountClear') onDiscountClear = new EventEmitter();

	totalAmountWithoutTax: any = 0;

	cardItems: any = [];

	salesChannelConst = Constants.salesChannelConst;

	constructor(private orderService: OrderService,
				private moneyService: MoneyService,
				private alertService: AlertService,
				private router: Router) {
	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['customerOrder']) {
			this.totalAmountWithoutTax = this.orderService.getTotalPriceWithoutTax(this.moneyService);
			this.cardItems = this.customerOrder.cart;
		}
	}

	ngOnDestroy(): void {
	}

	getMinimumOrderValue() {
		if(this.customerOrder.selectedMerchant.minimumOrder) {
			return this.customerOrder.selectedMerchant.minimumOrder;
		}
		return 0;
	}

	getTotalItems() {
		let total = 0;

		_.each(this.cardItems, (item: any) => {
			total += item.noOfItems;
		});

		return total;
	}

	isDeliveryFeeApplicable() {
		return this.orderService.isDeliveryFeeApplicable();
	}

	getDeliveryFee() {
		return this.orderService.getDeliveryFee();
	}

	hasCart() {
		return this.customerOrder && this.customerOrder.cart.length > 0;
	}

	hasTax() {
		return this.orderService.isTaxApplicable();
	}

	hasServiceFee() {
		return this.orderService.isServiceFeeApplicable();
	}

	getTaxRate() {
		return this.orderService.getTaxRate();
	}

	getTaxAmount() {
		return this.moneyService
			.gerPercentageAmount(this.totalAmountWithoutTax, this.getTaxRate());
	}

	getServiceFeeRate() {
		return this.orderService.getServiceFeeRate();
	}

	getServiceFeeAmount() {
		return this.moneyService
			.gerPercentageAmount(this.totalAmountWithoutTax, this.getServiceFeeRate());
	}

	getTotalPayableAmount() {
		let orderTotalWithTax = this.orderService.getTotalPrice(this.totalAmountWithoutTax, this.moneyService);
		return this.orderService.getTotalPayableAmount(orderTotalWithTax, this.voucherData, this.promoData, this.moneyService);
	}

	onCheckoutClick() {
		if (this.customerOrder.selectedMerchant.minimumOrder) {
			let totalPriceWithoutTaxes = this.totalAmountWithoutTax;
			if (this.customerOrder.selectedMerchant.minimumOrder > totalPriceWithoutTaxes) {
				this.alertService.showToaster(`
				Minimum amount for the order is AED ${this.customerOrder.selectedMerchant.minimumOrder}
				`, SETTINGS.TOASTER_MESSAGES.error);
				return false;
			}
		}

		this.router.navigate(['/checkout']);
	}

	onPlaceOrder() {
		this.onCheckoutOrder.emit(true);
	}

	isVoucherApplied() {
		return this.orderService.isVoucherApplied(this.voucherData);
	}

	isPromoApplied() {
		return this.orderService.isPromoApplied(this.promoData);
	}

	clearVouchers() {
		this.onVoucherClear.emit(true);
	}

	clearDiscounts() {
		this.onVoucherClear.emit(true);
		this.onDiscountClear.emit(true);
	}

}
