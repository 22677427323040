import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApplicationService} from "../application/application.service";
import {Constants} from "../../setting/constants";
import {SETTINGS} from "../../setting/commons.settings";

@Injectable()
export class CacheService {

	masterDataCache: any = {};

	constructor(private dataService: DataService,
				private applicationService: ApplicationService) {
	}

	expireCache() {
		this.masterDataCache = {};
	}

	expireCacheData(key: string) {
		this.masterDataCache[key] = null;
	}

	loadData(key: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.masterDataCache[key]) {
				resolve();
			} else {
				switch (key) {
					case Constants.masterDataKey.IE_PRICING_TEMPLATES:
						return this.getAllPricingTemplates(resolve, reject);

					case Constants.masterDataKey.IE_BUSINESS_TYPES:
						return this.getAllBusinessType(resolve, reject);

					case Constants.masterDataKey.IE_PAYMENT_TYPES:
						return this.getAllPaymentTypes(resolve, reject);

					case Constants.masterDataKey.IE_DELIVERY_TYPES:
						return this.getAllDeliveryTypes(resolve, reject);
					case Constants.masterDataKey.IE_SUB_BUSINESS_TYPES:
						return this.getAllSubBusinessTypes(resolve, reject);
					default:
						return;
				}
			}
		});
	}

	getData(key: string): any {
		return this.masterDataCache[key];
	}

	private getAllPricingTemplates(resolveParent, rejectParent): Promise<any> {
		return new Promise((resolve, reject) => {
			this.dataService.get(SETTINGS.ENDPOINTS.getConstants).subscribe((response: any) => {
				this.masterDataCache[Constants.masterDataKey.IE_PRICING_TEMPLATES] = response;
				resolve(response);
				resolveParent(response);
			}, () => {
				reject();
				rejectParent();
			});
		});
	}

	private getAllBusinessType(resolveParent, rejectParent): Promise<any> {
		return new Promise((resolve, reject) => {
			this.dataService.get(SETTINGS.ENDPOINTS.getBusinessTypes).subscribe((response: any) => {
				this.masterDataCache[Constants.masterDataKey.IE_BUSINESS_TYPES] = response;
				resolve(response);
				resolveParent(response);
			}, () => {
				reject();
				rejectParent();
			});
		});
	}

	private getAllPaymentTypes(resolveParent, rejectParent): Promise<any> {
		return new Promise((resolve, reject) => {
			this.dataService.get(SETTINGS.ENDPOINTS.getPaymentTypes).subscribe((response: any) => {
				this.masterDataCache[Constants.masterDataKey.IE_PAYMENT_TYPES] = response;
				resolve(response);
				resolveParent(response);
			}, () => {
				reject();
				rejectParent();
			});
		});
	}

	private getAllDeliveryTypes(resolveParent, rejectParent): Promise<any> {
		return new Promise((resolve, reject) => {
			this.dataService.get(SETTINGS.ENDPOINTS.getDeliveryTypes).subscribe((response: any) => {
				this.masterDataCache[Constants.masterDataKey.IE_DELIVERY_TYPES] = response;
				resolve(response);
				resolveParent(response);
			}, () => {
				reject();
				rejectParent();
			});
		});
	}

	private getAllSubBusinessTypes(resolveParent, rejectParent): Promise<any> {
		return new Promise((resolve, reject) => {
			this.dataService.get(SETTINGS.ENDPOINTS.getAllSubBusinessTypes).subscribe((response: any) => {
				this.masterDataCache[Constants.masterDataKey.IE_SUB_BUSINESS_TYPES] = response;
				resolve(response);
				resolveParent(response);
			}, () => {
				reject();
				rejectParent();
			});
		});
	}
}
