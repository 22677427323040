import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {BaseComponent} from './core/eatts/base/base.component';
import {ErrorPageComponent} from './core/eatts/content/error-page/error-page.component';
import {MerchantsGuard} from './main/guard/merchants.guard';
import {ProductSelectionGuard} from './main/guard/product-selection.guard';
import {CheckoutGuard} from './main/guard/checkout.guard';
import {LoginGuard} from './main/guard/login.guard';
import {IsLoggedInGuard} from './main/guard/is-logged-in.guard';
import {VerifiedNumberGuard} from './main/guard/verified-number.guard';
import {ReceiptGuard} from './main/guard/receipt.guard';

const routes: Routes = [


	{
		path: '',
		component: BaseComponent,
		children: [
			{
				path: 'auth',
				loadChildren: 'app/views/pages/auth/auth.module#AuthModule',
				canActivate: [LoginGuard, VerifiedNumberGuard]
			},

			{
				path: 'home',
				loadChildren: 'app/views/pages/home/home.module#HomeModule',
			},

			// {
			// 	path: 'eatts',
			// 	loadChildren: 'app/views/pages/landing/landing.module#LandingModule',
			// },

			{
				path: 'join',
				loadChildren: 'app/views/pages/join/join.module#JoinModule',
			},

			{
				path: 'restaurants',
				loadChildren: 'app/views/pages/merchant-list/merchant-list.module#MerchantListModule',
				canActivate: [MerchantsGuard],
			},

			{
				path: 'products',
				loadChildren: 'app/views/pages/product-selection/product-selection.module#ProductSelectionModule',
				canActivate: [ProductSelectionGuard],
			},

			{
				path: 'checkout',
				loadChildren: 'app/views/pages/checkout/checkout.module#CheckoutModule',
				canActivate: [CheckoutGuard, VerifiedNumberGuard],
			},

			{
				path: 'receipt',
				loadChildren: 'app/views/pages/order-receipt/order-receipt.module#OrderReceiptModule',
				canActivate: [ReceiptGuard, VerifiedNumberGuard]
			},

			{
				path: 'profile',
				loadChildren: 'app/views/pages/my-account/my-account.module#MyAccountModule',
				canActivate: [IsLoggedInGuard]
			},

			{
				path: 'terms',
				loadChildren: 'app/views/pages/terms/terms.module#TermsModule',
			},

			{
				path: 'external-payment',
				loadChildren: 'app/views/pages/genie/genie.module#GenieModule',
			},

			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	/** END: themes list end */

	{path: '**', redirectTo: 'eatts/error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
