// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// RxJS
import {Subscription} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {isLogInError} from "../../../../main/store/auth/auth.selectors";
import {AuthState} from "../../../../main/store/auth/auth.reducers";

@Component({
	selector: 'kt-auth-notice',
	templateUrl: './auth-notice.component.html',
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
	type: any;
	message: any = '';

	onLoginErrorSubs = new Subscription();

	/**
	 * Component Constructure
	 *
	 * @param authNoticeService
	 * @param cdr
	 */
	constructor(private cdr: ChangeDetectorRef,
				private store: Store<AuthState>) {
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {

		this.onLoginErrorSubs = this.store.pipe(
			select(isLogInError)
		).subscribe((isError) => {
			if (isError) {
				this.message = 'Invalid Login';
				this.type = 'danger';
				this.cdr.markForCheck();
			}
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.onLoginErrorSubs.unsubscribe();
	}
}
