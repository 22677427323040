import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class OtpService {

	onOTPConfirmationSuccess = new Subject();

	constructor() {
	}

	setOtpSuccess(otp : string, userDetails : any) {
		this.onOTPConfirmationSuccess.next(Object.assign({}, userDetails,{otp}, ));
	}
}
