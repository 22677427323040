import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationService} from '../service/application/application.service';
import {SETTINGS} from '../setting/commons.settings';

@Injectable()
export class VerifiedNumberGuard implements CanActivate {

	constructor(private applicationService: ApplicationService,
				private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.applicationService.hasLoggedInUser() && !this.applicationService.isUserMobileNoVerified()) {
			this.router.navigate([SETTINGS.PAGES.profile]);
			return false;
		}
		return true;
	}
}
