import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AlertService} from '../../../../../main/service/common/alert.service';
import {SignUpService} from '../../services/sign-up.service';
import {EmailValidator} from '../../../../../shared/validators/email.validator';
import {SETTINGS} from '../../../../../main/setting/commons.settings';
import {Subscription} from 'rxjs/Subscription';
import {AppUtils} from '../../../../../shared/app.utils';

@Component({
	selector: 'kt-pw-recover',
	templateUrl: './pw-recover.component.html',
	styleUrls: ['./pw-recover.component.scss']
})
export class PwRecoverComponent implements OnInit, OnDestroy {

	componentForm: FormGroup;
	formErrors: any;

	onForgotPasswordSubs = new Subscription();
	componentFormChangeSubs = new Subscription();

	constructor(public dialogRef: MatDialogRef<PwRecoverComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private formBuilder: FormBuilder,
				private signUpService: SignUpService,
				private alertService: AlertService) {

		this.formErrors = {
			email: {}
		};
	}

	ngOnInit() {
		this.componentForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.maxLength(250), EmailValidator.validateEmail]],
		});

		this.componentFormChangeSubs = this.componentForm.valueChanges.subscribe((form) => {
			this.formErrors = AppUtils.getFormErrors(this.componentForm, this.formErrors);
		});

		this.onForgotPasswordSubs = this.signUpService.onForgotPassword
			.subscribe((data: any) => {
				this.alertService.showToaster('A password reset link is sent to the email', SETTINGS.TOASTER_MESSAGES.success);
				this.dialogRef.close(false);
			});
	}

	ngOnDestroy(): void {
		this.onForgotPasswordSubs.unsubscribe();
		this.componentFormChangeSubs.unsubscribe();
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}


	isValidData() {
		return this.componentForm.valid;
	}

	isDirtyData() {
		return this.componentForm.dirty;
	}

	onConfirm() {
		
	}
}
