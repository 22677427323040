import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AppUtils} from "../../app.utils";

@Component({
	selector: 'kt-order-cancellation-confirmation',
	templateUrl: './order-cancellation-confirmation.component.html',
	styleUrls: ['./order-cancellation-confirmation.component.scss']
})
export class OrderCancellationConfirmationComponent implements OnInit, OnDestroy {
	title;
	componentForm: FormGroup;

	valueChangesSubs = new Subscription();

	formErrors: any = {};

	orderID;

	constructor(public dialogRef: MatDialogRef<OrderCancellationConfirmationComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private formBuilder: FormBuilder) {

		this.title = data['title'];

		this.formErrors = {
			remarks: {}
		};
	}

	ngOnInit() {
		this.componentForm = this.formBuilder.group({
			remarks: [null, [Validators.maxLength(200)]]
		});

		this.valueChangesSubs = this.componentForm.valueChanges
			.subscribe((form) => {
				this.formErrors = AppUtils.getFormErrors(this.componentForm, this.formErrors);
			});
	}

	ngOnDestroy(): void {
		this.valueChangesSubs.unsubscribe();
	}

	onCancelClick() {
		this.dialogRef.close(false);
	}

	isValid() {
		return this.componentForm.valid;
	}

	isDirty() {
		return this.componentForm.dirty;
	}

	onYesClick() {
		this.dialogRef.close({
			remarks: this.componentForm.controls.remarks.value
		});
	}
}
