import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/nx';
import {map} from 'rxjs/internal/operators';
import {AuthState} from './auth.reducers';
import {LoginService} from '../../service/authentication/login.service';
import {
	AuthActionTypes,
	Login,
	LoginRequest,
	Logout,
	MobileNumberLoginRequest,
	MobileNumberLoginRequestSuccess,
	MobileOTPConfirmation,
	MobileOTPConfirmationSuccess,
	OTPResendRequest,
	OTPResendRequestSuccess
} from './auth.actions';
import {Store} from '@ngrx/store';
import {SETTINGS} from '../../setting/commons.settings';
import {LocalStorage} from 'ngx-webstorage';
import {AuthNoticeService} from '../../service/authentication/auth-notice.service';
import {Router} from '@angular/router';
import {DataResetService} from '../../service/application/data-reset.service';
import {MasterDataService} from '../../service/data/master-data.service';
import {PrivilegeService} from '../../service/authentication/privilege.service';
import {ApplicationService} from '../../service/application/application.service';
import {UrlEncodeService} from '../../service/application/url-encode.service';
import {OtpService} from '../../service/authentication/otp.service';

@Injectable()
export class AuthEffects {

	@LocalStorage(SETTINGS.ACCESS_TOKEN)
	private accessToken: any;

	@LocalStorage(SETTINGS.REFRESH_TOKEN)
	private refreshToken: any;

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	otpConfirmMobileNumber;

	@LocalStorage(SETTINGS.STORAGE.LOGIN_CONFIRMED_OTP)
	loginOTP;

	constructor(private actions$: Actions,
				private dataPersistence: DataPersistence<AuthState>,
				private loginService: LoginService,
				private masterDataService: MasterDataService,
				private authNoticeService: AuthNoticeService,
				private dataResetService: DataResetService,
				private privilegeService: PrivilegeService,
				private applicationService: ApplicationService,
				private urlEncodeService: UrlEncodeService,
				private otpService: OtpService,
				private store: Store<AuthState>,
				private router: Router) {

	}

	@Effect() userMobileNoNext$ = this.dataPersistence.fetch(
		AuthActionTypes.MobileNumberLoginRequest,
		{
			run: (action: MobileNumberLoginRequest, state: AuthState) => {
				return this.loginService.doRequestOTP(action.payload)
					.pipe(
						map(res => {
							this.otpConfirmMobileNumber = this.urlEncodeService.encode(action.payload.mobileNumber);
							return new MobileNumberLoginRequestSuccess();
						})
					);
			},
			onError: () => {
				this.authNoticeService.setNotice('Invalid Mobile Number', 'danger');
			}
		}
	);

	@Effect() userOTPResendRequest$ = this.dataPersistence.fetch(
		AuthActionTypes.OTPResendRequest,
		{
			run: (action: OTPResendRequest, state: AuthState) => {
				return this.loginService.doRequestOTP(action.payload)
					.pipe(
						map(res => {
							return new OTPResendRequestSuccess();
						})
					);
			},
			onError: () => {
				this.authNoticeService.setNotice('Invalid Mobile Number', 'danger');
			}
		}
	);

	@Effect() otpRedirect$ = this.dataPersistence.fetch(
		AuthActionTypes.MobileNumberLoginRequestSuccess,
		{
			run: (action: MobileNumberLoginRequestSuccess, state: AuthState) => {
				this.router.navigate(['/auth' + SETTINGS.PAGES.confirmOTP]);

			},
			onError: (action: MobileNumberLoginRequestSuccess, error) => {
				console.error(error);
			}
		}
	);

	@Effect() confirmOTP$ = this.dataPersistence.fetch(
		AuthActionTypes.MobileOTPConfirmation,
		{
			run: (action: MobileOTPConfirmation, state: AuthState) => {
				return this.loginService.confirmOTPLogin(action.payload)
					.pipe(
						map(res => {
							this.otpService.setOtpSuccess(action.payload.otpCode, res);
							return new MobileOTPConfirmationSuccess();
						})
					);
			},
			onError: () => {
				this.authNoticeService.setNotice('Invalid Mobile Number', 'danger');
			}
		}
	);

	@Effect() userLogin$ = this.dataPersistence.fetch(
		AuthActionTypes.LoginRequest,
		{
			run: (action: LoginRequest, state: AuthState) => {
				return this.loginService.doLogin(action.payload)
					.pipe(
						map(res => {
							this.onSuccessLogin(res);
							return new Login(this.getLoginData(res));
						})
					);
			},
			onError: () => {
				this.authNoticeService.setNotice('Invalid Login', 'danger');
			}
		}
	);

	@Effect() logout$ = this.dataPersistence.fetch(
		AuthActionTypes.Logout,
		{
			run: (action: Logout, state: AuthState) => {
				localStorage.removeItem(SETTINGS.ACCESS_TOKEN);
				localStorage.removeItem(SETTINGS.REFRESH_TOKEN);
				this.privilegeService.setUserPrivileges([]);

				this.dataResetService.resetNonOrderData();
				this.router.navigate([SETTINGS.PAGES.home]);

			},
			onError: (action: Logout, error) => {
				console.error(error);
			}
		}
	);

	onSuccessLogin(loginResponse: any) {
		//1.add tokes to local storage
		this.accessToken = loginResponse.accessToken;
		this.refreshToken = loginResponse.refreshToken;

		//2.add user to local storage
		this.applicationService.setLoggedInUser(loginResponse.user);

		this.privilegeService.setUserPrivileges(loginResponse.user.privileges);

		this.otpConfirmMobileNumber = null;
		this.loginOTP = null;
	}

	getLoginData(loginResponse: any) {
		return {
			authToken: loginResponse.accessToken,
			refreshToken: loginResponse.refreshToken,
			user: loginResponse.user
		};
	}
}
