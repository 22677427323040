import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../setting/commons.settings';
import {LoginService} from '../service/authentication/login.service';

@Injectable()
export class IsLoggedInGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_MERCHANT_SEARCH_DATA)
	selectedMerchantSearchData;

	constructor(private router: Router,
				private loginService: LoginService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.loginService.isUserLoggedIn()) {
			this.router.navigateByUrl('/login');
			return false;
		}
		return true;
	}

}
