import {Subscription} from 'rxjs';
// Angular
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// language list
import {LayoutConfigService} from './main/service/layout/layout-config.service';
import {CommonService} from './main/service/common/common.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MasterDataService} from './main/service/data/master-data.service';
import {ApplicationService} from './main/service/application/application.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'div[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	loaderConfig = {
		'bgsColor': '#00ACC1',
		'bgsOpacity': 0.5,
		'bgsPosition': 'bottom-right',
		'bgsSize': 60,
		'bgsType': 'ball-spin-clockwise',
		'blur': 5,
		'fgsColor': '#d8007b',
		'fgsPosition': 'center-center',
		'fgsSize': 40,
		'fgsType': 'rectangle-bounce-party',
		'gap': 24,
		'logoPosition': 'center-center',
		'logoSize': 60,
		'logoUrl': './assets/media/images/order_karapan1.png',
		'masterLoaderId': 'master',
		'overlayBorderRadius': '0',
		'overlayColor': 'rgba(40, 40, 40, 0.8)',
		'pbColor': '#00ACC1',
		'pbDirection': 'ltr',
		'pbThickness': 3,
		'hasProgressBar': true,
		'text': '',
		'textColor': '#FFFFFF',
		'textPosition': 'center-center',
		'threshold': 500
	};

	constructor(private router: Router,
				private layoutConfigService: LayoutConfigService,
				private commonService: CommonService,
				private ngxService: NgxUiLoaderService,
				private masterDataService: MasterDataService,
				private applicationService: ApplicationService,
				private cdRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.applicationService.loadPublicKey();
		this.masterDataService.reloadApplicationProperties();

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// scroll to top on every route change
				window.scrollTo(0, 0);
			}
		});
		this.unsubscribe.push(routerSubscription);

		const loadingStatusSubs = this.commonService.getLoadingStatus()
			.subscribe((isLoading: any) => {
				if (isLoading.status) {
					this.ngxService.start();
				} else {
					this.ngxService.stop();
				}
				this.cdRef.detectChanges();
			});

		const onApiError = this.commonService.onError.subscribe(onError => {
			if (onError) {
				this.ngxService.stop();
				this.cdRef.detectChanges();
			}
		});
		this.masterDataService.reloadSystemParameters();

		this.unsubscribe.push(loadingStatusSubs);
		this.unsubscribe.push(onApiError);
	}

	ngAfterViewInit(): void {

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
