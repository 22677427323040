import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AlertService} from '../../../main/service/common/alert.service';
import * as _ from 'lodash';
import * as __ from 'underscore';
import {AppUtils} from '../../app.utils';
import {SETTINGS} from '../../../main/setting/commons.settings';
import {OrderService} from '../../../main/service/order/order.service';
import {ProductSelectionHelperService} from '../../../main/service/order/product-selection-helper.service';

@Component({
	selector: 'kt-cart-item-select-option',
	templateUrl: './cart-item-select-option.component.html',
	styleUrls: ['./cart-item-select-option.component.scss']
})
export class CartItemSelectOptionComponent implements OnInit, OnDestroy {

	componentForm: FormGroup;
	formErrors: any;

	product: any = {};
	optionData: any = {};
	imageSplit: any = [];

	componentFormChangeSubs = new Subscription();
	onChildProductOptionsLoadSubs = new Subscription();
	onChildProductOptionsLoadEditSubs = new Subscription();

	itemSelectionDetails: any = {
		noOfItems: 1,
		unitPrice: 0,
		choices: {},
		productID: null,
		instructions: ''
	};

	productOptionIDWiseValidity = {};

	isOnUpdate;

	constructor(public dialogRef: MatDialogRef<CartItemSelectOptionComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private formBuilder: FormBuilder,
				private orderService: OrderService,
				private productSelectionHelperService: ProductSelectionHelperService,
				private alertService: AlertService) {

		this.product = data['product'];
		this.optionData = data['optionData'];

		let cartItemIndex = data['cartItemIndex'];
		if (!_.isUndefined(cartItemIndex) && !_.isNull(cartItemIndex)) {
			this.itemSelectionDetails = this.orderService.getCart()[cartItemIndex];
			this.isOnUpdate = true;

			let selectedProdOptionWiseChildOptions: any = {};
			_.each(_.keys(this.itemSelectionDetails.choices), (prodOptionID: any) => {
				let child = [];
				this.itemSelectionDetails.choices[parseInt(prodOptionID)].forEach((item: any) => {
					child = [...child, ...item.childOptionIDs];
				});

				selectedProdOptionWiseChildOptions[prodOptionID] = child;
			});

			this.loadChildOptionsOnEditMode(selectedProdOptionWiseChildOptions);
		} else {
			this.itemSelectionDetails.unitPrice = this.product.price;
		}

		if (this.product.imageUrls && this.product.imageUrls[0]) {
			this.imageSplit = this.product.imageUrls[0].split(',');
		}

		this.formErrors = {
			instructions: {}
		};
	}

	ngOnInit() {
		this.componentForm = this.formBuilder.group({
			instructions: [this.itemSelectionDetails.instructions, [Validators.maxLength(200)]]
		});

		this.componentFormChangeSubs = this.componentForm.valueChanges
			.debounceTime(200)
			.distinctUntilChanged()
			.subscribe(form => {
				this.formErrors = AppUtils.getFormErrors(this.componentForm, this.formErrors);
			});

		this.onChildProductOptionsLoadSubs = this.productSelectionHelperService.onChildProductOptionsLoad
			.subscribe((data: any) => {
				this.addChildOptionsToProductOptionArray(data);
			});

		this.onChildProductOptionsLoadEditSubs = this.productSelectionHelperService.onChildProductOptionsLoadEdit
			.subscribe((data: any) => {
				_.keys(data.parentToChildMap).forEach((parentProdOptionID: any) => {
					if (data.parentToChildMap[parentProdOptionID].length > 0) {
						let req: any = {};
						req.response = {};
						req.response.productOptions = [];
						req.response.choices = {};
						req.prvProductOptionID = parentProdOptionID;

						data.parentToChildMap[parentProdOptionID].forEach((childProdOptionID: any) => {
							req.response.choices[childProdOptionID] = data.response.choices[childProdOptionID];

							let find = data.response.productOptions.find((prodOption: any) => {
								return prodOption.productOptionID == childProdOptionID;
							});

							if (find) {
								req.response.productOptions.push(find);
							}
						});

						this.addChildOptionsToProductOptionArray(req);
					}
				});
			});
	}

	ngOnDestroy(): void {
		this.componentFormChangeSubs.unsubscribe();
		this.onChildProductOptionsLoadSubs.unsubscribe();
		this.onChildProductOptionsLoadEditSubs.unsubscribe();
	}

	loadChildOptionsOnEditMode(parentToChildOptionIDs) {
		let request = _.flattenDeep(_.values(parentToChildOptionIDs));
		if (request && request.length > 0) {
			this.productSelectionHelperService.getChildOptionsOfChoicesToEdit({
				merchantProductOptionIDs: request
			}, parentToChildOptionIDs);
		}
	}

	getAlreadyCheckedChoiceIds(productOptionID) {
		let choices = this.itemSelectionDetails.choices[productOptionID];
		if (choices) {
			return choices.map((item) => item.merchantProductOptionChoiceID);
		}
		return [];
	}

	getImage(imageUrl) {
		if (imageUrl) {
			return SETTINGS.BASE_IMAGE_URL + imageUrl;
		}
		return SETTINGS.DEFAULT_MERCHANT_PRODUCT_IMAGE;
	}

	onSelectionChange($event) {
		if ($event.loadExtraOptions.length > 0) {
			this.productSelectionHelperService.getChildOptionsOfChoices({
				merchantProductID: this.product.productID,
				merchantProductOptionIDs: $event.loadExtraOptions
			}, $event.prvProductOptionID);
		}

		if ($event.removeExtraOptions.length > 0) {
			this.removeChildOptionsToProductOptionArray($event.removeExtraOptions);
		}
		this.productOptionIDWiseValidity[$event.productOptionID] = $event.isValid;
		this.itemSelectionDetails.choices[$event.productOptionID] = $event.checkedItems;
	}

	addChildOptionsToProductOptionArray(data) {
		let copy = _.cloneDeep(this.optionData);
		let prvProductOptionID = data.prvProductOptionID;
		let productOptions = copy.productOptions;
		let choices = copy.choices;

		let externalOptions = _.cloneDeep(data.response.productOptions).map((item) => {
			return {...item, isExternal: true};
		});

		let index = _.findIndex(productOptions, (prodOption: any) => {
			return prodOption.productOptionID == prvProductOptionID;
		});

		productOptions.splice((index + 1), 0, ...externalOptions);
		choices = {...choices, ...data.response.choices};

		copy.productOptions = productOptions;
		copy.choices = choices;

		this.optionData = copy;
	}

	removeChildOptionsToProductOptionArray(removeProductOptions) {
		let copy = _.cloneDeep(this.optionData);

		_.remove(copy.productOptions, (prodOption: any) => {
			return __.contains(removeProductOptions, prodOption.productOptionID);
		});

		removeProductOptions.forEach((productOptionID: any) => {
			delete copy.choices[productOptionID];
			delete this.productOptionIDWiseValidity[productOptionID];
			delete this.itemSelectionDetails.choices[productOptionID];
		});

		this.optionData = copy;
	}

	isValid() {
		let isValid = true;
		let keys = _.keys(this.productOptionIDWiseValidity);
		for (let i = 0; i < keys.length; i++) {
			if (!this.productOptionIDWiseValidity[keys[i]]) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	//TODO move to common place
	getItemPrice() {
		let singleUnitPrice = this.itemSelectionDetails.unitPrice;
		if (!_.isEmpty(this.itemSelectionDetails.choices)) {
			_.each(_.keys(this.itemSelectionDetails.choices), (key: any) => {
				_.each(this.itemSelectionDetails.choices[key], (item: any) => {
					singleUnitPrice += item.price;
				});
			});
		}

		return singleUnitPrice * this.itemSelectionDetails.noOfItems;
	}

	onItemAdd() {
		this.itemSelectionDetails.noOfItems++;
	}

	onItemRemove() {
		if (this.itemSelectionDetails.noOfItems > 1) {
			this.itemSelectionDetails.noOfItems--;
		}
	}

	close() {
		this.dialogRef.close(false);
	}

	onAdd() {
		if (!this.isValid()) {
			this.alertService.showToaster('Please select required options', SETTINGS.TOASTER_MESSAGES.error);
			return;
		}
		this.itemSelectionDetails.productID = this.product.productID;
		this.itemSelectionDetails.product = this.product;
		this.itemSelectionDetails.productOptions = this.optionData.productOptions;
		this.itemSelectionDetails.instructions = this.componentForm.controls.instructions.value;
		this.dialogRef.close(this.itemSelectionDetails);
	}
}
