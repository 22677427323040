import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../../../../main/setting/commons.settings';
import {MobileNumberLoginRequest} from '../../../../main/store/auth/auth.actions';
import {AppState} from '../../../../main/store/index';
import {AuthNoticeService} from '../../../../main/service/authentication/auth-notice.service';
import {Constants} from '../../../../main/setting/constants';
import {LoginService} from '../../../../main/service/authentication/login.service';
import {AuthService} from 'angularx-social-login';
import {ApplicationService} from '../../../../main/service/application/application.service';
import {NumberValidator} from '../../../../shared/validators/number.validator';
import {AppUtils} from '../../../../shared/app.utils';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

	@LocalStorage(SETTINGS.PUBLIC_KEY)
	private publicKey: any;

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	goToOTPConfirmation;

	// Public params
	loginForm: FormGroup;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	onLoginSubs = new Subscription();

	signInClicked: boolean;

	userTypeConst = Constants.userTypeConst;
	salesChannelConst = Constants.salesChannelConst;

	formErrors: any;

	constructor(private router: Router,
				private loginService: LoginService,
				private authNoticeService: AuthNoticeService,
				private store: Store<AppState>,
				private fb: FormBuilder,
				private authService: AuthService,
				private applicationService: ApplicationService,
				private route: ActivatedRoute) {
		this.unsubscribe = new Subject();

		this.formErrors = {
			mobileNo: {}
		};
	}

	ngOnInit(): void {
		this.goToOTPConfirmation = null;
		this.initLoginForm();

		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.onLoginSubs.unsubscribe();
	}

	initLoginForm() {
		this.loginForm = this.fb.group({
			mobileNo: [null, [Validators.required, NumberValidator.mobileNumber, Validators.maxLength(10)]],
		});
	}

	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.formErrors = AppUtils.getFormErrors(this.loginForm, this.formErrors);
			return;
		}

		this.store.dispatch(new MobileNumberLoginRequest({
			mobileNumber: controls['mobileNo'].value.trim(),
			salesChannel: this.salesChannelConst.WEB,
		}));
	}
}
