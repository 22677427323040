import {Injectable} from '@angular/core';
import {DataService} from '../../../../main/service/data/data.service';
import {SETTINGS} from '../../../../main/setting/commons.settings';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class SignUpService {

	onCustomerRegiter = new Subject();

	onForgotPassword = new Subject();

	constructor(private dataService: DataService) {
	}

	registerCustomer(request: any) {
		this.dataService.post(SETTINGS.ENDPOINTS.registerCustomerV2, request)
			.subscribe((response: any) => {
				this.onCustomerRegiter.next(response);
			});
	}
}
