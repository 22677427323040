import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as _ from 'underscore';
import {MatCheckboxChange} from '@angular/material';

@Component({
	selector: 'kt-item-option',
	templateUrl: './item-option.component.html',
	styleUrls: ['./item-option.component.scss']
})
export class ItemOptionComponent implements OnInit, AfterViewInit, OnChanges {

	@Input('productOption') productOption: any = {};
	@Input('choices') choices: any = [];
	@Input('checkedChoiceIds') checkedChoiceIds: any = [];

	@Output('onSelectionChange') onSelectionChange = new EventEmitter();

	checkedItemIds = [];

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['checkedChoiceIds']) {
			this.checkedItemIds = _.uniq([...this.checkedItemIds, ...this.checkedChoiceIds]);
		}
	}

	ngAfterViewInit(): void {
		this.emitChange([], []);
	}

	changeCheckbox($event: MatCheckboxChange, option) {
		let loadExtraOptions = [];
		let removeExtraOptions = [];
		let prvProductOptionID;

		if ($event.checked) {
			if (this.checkedItemIds.length < this.productOption.maxCount) {
				this.checkedItemIds.push(option.merchantProductOptionChoiceID);
				loadExtraOptions = [...option.childOptionIDs];
				prvProductOptionID = this.productOption.productOptionID;
			}
		} else {
			this.checkedItemIds = _.without(this.checkedItemIds, option.merchantProductOptionChoiceID);
			removeExtraOptions = [...option.childOptionIDs];
		}

		this.emitChange(loadExtraOptions, removeExtraOptions, prvProductOptionID);
	}

	isChecked(id) {
		return _.contains(this.checkedItemIds, id);
	}

	disabled(id) {
		return this.checkedItemIds.length == this.productOption.maxCount
			&& !_.contains(this.checkedItemIds, id);
	}

	isValid() {
		let mandatoryCheck = this.productOption.mandatory ? this.checkedItemIds.length > 0 : true;

		if (mandatoryCheck) {
			let maxCountCheck = (this.checkedItemIds.length <= this.productOption.maxCount);
			if (maxCountCheck) {
				if (this.productOption.mandatory) {
					let minCountCheck = (this.checkedItemIds.length >= this.productOption.minCount);
					return minCountCheck;
				} else {
					return true;
				}

			}
			return false;
		}
		return false;
	}

	emitChange(loadExtraOptions, removeExtraOptions, prvProductOptionID?) {
		this.onSelectionChange.emit({
			isValid: this.isValid(),
			checkedItems: this.getCheckedItems(),
			productOptionID: this.productOption.productOptionID,
			loadExtraOptions: loadExtraOptions,
			removeExtraOptions: removeExtraOptions,
			prvProductOptionID: prvProductOptionID,
		});
	}

	getCheckedItems() {
		let list = _.filter(this.choices, (option: any) => {
			return _.contains(this.checkedItemIds, option.merchantProductOptionChoiceID);
		});

		return list;
	}
}
