// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
// State
import {currentUser} from '../../../../../main/store/auth/auth.selectors';
import {Logout} from '../../../../../main/store/auth/auth.actions';
import {AuthState} from "../../../../../main/store/auth/auth.reducers";
import {Router} from "@angular/router";
import {LoginService} from "../../../../../main/service/authentication/login.service";

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<any>;

	@Input() showAvatar: boolean = true;
	@Input() showHi: boolean = true;
	@Input() showBadge: boolean = false;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AuthState>,
				private router: Router,
				private loginService: LoginService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	logout() {
		this.loginService.onLogOut();
		this.store.dispatch(new Logout());
	}
}
