import {Injectable} from '@angular/core';

declare const Dinero: any;

@Injectable()
export class MoneyService {

	constructor() {
		Dinero.defaultPrecision = 2;
		Dinero.defaultCurrency = 'AED';

		Dinero.globalRoundingMode = 'HALF_EVEN';
		Dinero.globalFormatRoundingMode = 'HALF_EVEN';
		Dinero.globalFormat = '0,0.00';
	}

	add(a: number, b: number) {
		return this.getActualAmount(this.getDineroObject(a).add(this.getDineroObject(b)));
	}

	subtract(a: number, b: number) {
		return this.getActualAmount(this.getDineroObject(a).subtract(this.getDineroObject(b)));
	}

	multiply(a: number, multiplier: number) {
		return this.getActualAmount(this.getDineroObject(a).multiply(multiplier));
	}

	divide(a: number, divisor: number) {
		return this.getActualAmount(this.getDineroObject(a).divide(divisor));
	}

	gerPercentageAmount(a: number, percentage: number) {
		let final = this.getDineroObject(a).multiply(percentage).divide(100);
		return this.getActualAmount(final);
	}

	addPercentage(a: number, percentage: number) {
		return this.getActualAmount(this.getDineroObject(a).percentage(percentage).add(this.getDineroObject(a)));
	}

	getFormattedAmount(a: number) {
		return 'AED ' + this.getDineroObject(a).toFormat();
	}

	private getDineroObject(rawAmount: number) {
		let amountInt = parseInt((rawAmount * 100).toFixed(0));
		return Dinero({amount: amountInt});
	}

	private getActualAmount(dineroObjet) {
		return dineroObjet.getAmount() / 100;
	}
}
