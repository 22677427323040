import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../setting/commons.settings';

@Injectable()
export class ReceiptGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_RECEIPT)
	orderReceiptData;

	constructor(private router: Router) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (!this.orderReceiptData) {
			this.router.navigate([SETTINGS.PAGES.home]);
			return false;
		}
		return true;
	}
}
