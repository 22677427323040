import {createSelector} from '@ngrx/store';

export const selectAuthState = state => state.authState;


export const isLoggedIn = createSelector(
	selectAuthState,
	auth => auth.loggedIn
);

export const currentUser = createSelector(
	selectAuthState,
	auth => auth.user
);

export const isLogInError = createSelector(
	selectAuthState,
	auth => auth.isLoginError
);
