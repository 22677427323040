import {Injectable} from '@angular/core';
import {SETTINGS} from '../../setting/commons.settings';
import {DataService} from '../data/data.service';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class ProductSelectionHelperService {

	onSelectCartItemToEdit = new Subject();

	onChildProductOptionsLoad = new Subject();
	onChildProductOptionsLoadEdit = new Subject();

	constructor(private dataService: DataService) {
	}

	getSelectCartItemToEdit(request: any) {
		this.dataService.post(SETTINGS.ENDPOINTS.getSelectedMerchantProductDetails, request)
			.subscribe((response: any) => {
				this.onSelectCartItemToEdit.next({
					result: response,
					request: request
				});
			});
	}

	getChildOptionsOfChoices(request: any, prvProductOptionID) {
		this.dataService.post(SETTINGS.ENDPOINTS.getChildOptionsOfChoices, request)
			.subscribe((response: any) => {
				this.onChildProductOptionsLoad.next({
					prvProductOptionID,
					response
				});
			});
	}

	getChildOptionsOfChoicesToEdit(request: any, parentToChildMap) {
		this.dataService.post(SETTINGS.ENDPOINTS.getChildOptionsOfChoices, request)
			.subscribe((response: any) => {
				this.onChildProductOptionsLoadEdit.next({
					parentToChildMap,
					response
				});
			});
	}
}
