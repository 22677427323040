import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	Renderer2,
	HostListener
} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {LoginService} from '../../../main/service/authentication/login.service';
import {AuthState} from '../../../main/store/auth/auth.reducers';
import {Store} from '@ngrx/store';
import {Logout} from '../../../main/store/auth/auth.actions';
import {SETTINGS} from '../../../main/setting/commons.settings';
import {OrderService} from '../../../main/service/order/order.service';
import {Subscription} from 'rxjs/Subscription';
import {merge} from 'rxjs';

@Component({
	selector: 'kt-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('ktHeader') ktHeader: ElementRef;

	onOrderChangeSubs = new Subscription();

	hasCart: boolean;
	cartItemCount;

	constructor(
		private router: Router,
		public loader: LoadingBarService,
		private store: Store<AuthState>,
		public loginService: LoginService,
		private orderService: OrderService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		// page progress bar percentage
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});
	}

	ngOnInit(): void {
		this.onOrderChangeSubs =
			merge(this.orderService.onOrderChange,
				this.orderService.onCartReset,
				this.orderService.onOrderReset)
				.subscribe((data) => {
					this.cartItemCount = this.orderService.getCartItemCount();
					this.hasCart = this.cartItemCount > 0;

					this.changeDetectorRef.detectChanges();
				});
	}

	ngAfterViewInit(): void {
	}

	@HostListener('window:scroll', [])
	onWindowScroll() {
		const screenWidth = window.innerWidth;
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		const element = document.getElementById('fixedHeader');

		if (scrollTop > 100 && screenWidth < 830) {
			element.style.display = 'none';
		} else {
			element.style.display = 'flex';
		}
	}

	ngOnDestroy(): void {
		this.onOrderChangeSubs.unsubscribe();
	}

	onLogout() {
		this.loginService.onLogOut();
		this.store.dispatch(new Logout());
	}

	goToHome() {
		this.router.navigate([SETTINGS.PAGES.home]);
	}

	login() {
		this.router.navigate([SETTINGS.PAGES.login]);
	}

	signUp() {
		this.router.navigate([SETTINGS.PAGES.login]);
	}

	myOrders() {
		this.router.navigate([SETTINGS.PAGES.profile]);
	}

	goToCart() {
		if (this.hasCart) {
			this.router.navigate([SETTINGS.PAGES.products]);
		}
	}
}
