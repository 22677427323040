// Angular
import {Component, OnInit} from '@angular/core';
import {LayoutConfigService} from '../../../main/service/layout/layout-config.service';
import {Router} from '@angular/router';
import {SETTINGS} from '../../../main/setting/commons.settings';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	// Public properties
	today: number = Date.now();

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
				private router: Router) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
	}

	goToTerms() {
		this.router.navigate([SETTINGS.PAGES.terms]);
	}

	goToPrivacy() {

	}
}
