import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DataService} from '../data/data.service';
import {SETTINGS} from '../../setting/commons.settings';
import {Subject} from 'rxjs/Subject';
import {LocalStorage} from 'ngx-webstorage';
import {UrlEncodeService} from '../application/url-encode.service';
import {Router} from '@angular/router';

@Injectable()
export class ProductCheckoutHelperService {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_RECEIPT)
	orderReceiptData;

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_GENIE_INIT)
	genieInitResponseJson;

	onShowHidePlaceOrderBtn = new BehaviorSubject(false);

	onOrderCheckout = new BehaviorSubject({});
	onOrderCheckoutSubject = new Subject();

	addCardResponse = null;
	onAddCardResponse = new Subject();

	genieInitResponse = null;
	onGenieInitResponse = new Subject();
	onGeniePaymentSuccess = new Subject();
	onGeniePaymentFailed = new Subject();

	onPaymentCompleted = new Subject();

	constructor(private dataService: DataService,
				private urlEncodeService: UrlEncodeService,
				private router: Router) {
	}

	checkoutOrder(order) {
		this.dataService.post(SETTINGS.ENDPOINTS.checkoutOrder, order)
			.subscribe((response: any) => {
				this.orderReceiptData = this.urlEncodeService.encode(JSON.stringify(response));
				this.onOrderCheckoutSubject.next(response);
				this.onOrderCheckout.next(response);

				this.router.navigate(['/receipt']);
			});
	}

	placeOrderWhileAddNewCard(order) {
		this.dataService.post(SETTINGS.ENDPOINTS.placeOrderWhileAddNewCard, order)
			.subscribe((response: any) => {
				this.addCardResponse = response;
				this.onAddCardResponse.next(response);
			});
	}

	placeOrderUsingGenie(order) {
		this.dataService.post(SETTINGS.ENDPOINTS.placeOrderUsingGenie, order)
			.subscribe((response: any) => {
				this.genieInitResponse = response;
				this.genieInitResponseJson = this.urlEncodeService.encode(JSON.stringify(response));
				this.onGenieInitResponse.next(response);
			});
	}

	genieSuccessFailedResponse(isSuccess, params: any) {
		let conf;
		if (isSuccess) {
			conf = SETTINGS.ENDPOINTS.genieSuccessUrl;
		} else {
			conf = SETTINGS.ENDPOINTS.genieFailedUrl;
		}
		this.dataService.get(conf, params)
			.subscribe((response: any) => {
				if (response.success) {
					this.onGeniePaymentSuccess.next(response);
				} else {
					this.onGeniePaymentFailed.next(response);
				}
			});
	}

	checkPayment(orderID) {
		const data = Object.assign({}, SETTINGS.ENDPOINTS.checkOnlinePaymentStatus);
		data.url = data.url + '/' + orderID;
		this.dataService.get(data)
			.subscribe((response: any) => {
				this.onPaymentCompleted.next(response);
			});
	}

	getCardPaymentSuccessOrderById(orderID) {
		const data = Object.assign({}, SETTINGS.ENDPOINTS.getOrderDetailDTO);
		data.url = data.url + '/' + orderID;
		this.dataService.get(data)
			.subscribe((response: any) => {
				this.orderReceiptData = this.urlEncodeService.encode(JSON.stringify(response));
				this.onOrderCheckoutSubject.next(response);
				this.onOrderCheckout.next(response);

				this.router.navigate(['/receipt']);
			});
	}


	reinstateVoucherAndPromocodes(orderID) {
		const data = Object.assign({}, SETTINGS.ENDPOINTS.reinstateVoucherAndPromocodes);
		data.url = data.url + '/' + orderID;
		this.dataService.get(data)
			.subscribe((response: any) => {
			});
	}
}
