import {ShowMessageDto} from '../dto/show-message-dto';

export class SETTINGS {

	public static PUBLIC_KEY = 'Rt5Wx%4d';
	public static ACCESS_TOKEN = 'Eop_ubF';
	public static REFRESH_TOKEN = 'Xer_09s';
	public static LOGGED_USER_ENC = 'Zedij7&';
	public static IS_FB_USER_ENC = 'IsEfGTe3&';
	public static USER_PRIVILEGES = 'C4d4%rd';
	public static DATE_FORMAT = 'DD/MM/YYYY';
	public static DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

	public static BASE_IMAGE_URL = 'https://s3.ap-southeast-1.amazonaws.com/orderkarapan-dev/';

	public static BASE_IMAGE_URL_LIVE = 'https://orderkarapan-dev.s3.ap-southeast-1.amazonaws.com/images/';

	public static DEFAULT_RESTAURANT_LOGO_IMAGE = 'https://orderkarapan-dev.s3.ap-southeast-1.amazonaws.com/images/default-restaurant.jpg';
	public static DEFAULT_RESTAURANT_HEADER_IMAGE = 'https://orderkarapan-dev.s3.ap-southeast-1.amazonaws.com/images/default-restaurant-banner.jpg';
	public static DEFAULT_MERCHANT_PRODUCT_IMAGE = 'https://orderkarapan-dev.s3.ap-southeast-1.amazonaws.com/images/default-product.jpg';

	public static RIDER_SIGN_UP_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSecidczvcYyhPAW-HD7Ge07V3dMTJmpzhPfoEcTvOhUBVTBTQ/viewform';
	public static RESTAURANT_SIGN_UP_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfrUJG0cOeHnAvYRVYkvGK-xWcH1qhZ8i3nGkS_vSPAe5coxA/viewform';
	public static CAREER_SIGN_UP_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdU0st7spMXzP2zH21MXNZbaQ8rvvSYxuCNXz40F6Wn4DahOA/viewform';

	public static GOOGLE_MAP_API_KEY = '';

	public static TEST_SYSTEM = 'N';
	public static TEST_SYSTEM_DISPLAY_NAME = 'Demo';

	public static DATE_TIME = {
		DEFAULT_DATE_FORMAT: 'DD/MM/YYYY',
		DEFAULT_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
	};

	public static UPLOAD_IMAGE_SIZE_MAX_MB = 25;
	public static UPLOAD_IMAGE_ALLOWED_EXTENSIONS = ['jpg', 'png', 'jpeg'];

	public static TOASTER_MESSAGES = {
		success: 'SUCCESS',
		error: 'ERROR',
		warning: 'WARNING',
		info: 'INFO',
		custom: 'CUSTOM'
	};

	public static PAGES = {
		home: '/home',
		login: '/auth/login',
		signUp: '/auth/sign-up',
		checkout: '/checkout',
		profile: '/profile',
		products: '/products',
		terms: '/terms',
		confirmOTP: '/otp-confirmation',
		landing: '/eatts'
	};

	public static KEYS = {
		PUBLIC_KEY:
			'-----BEGIN PUBLIC KEY-----\n' +
			'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDpBBsjh/8fbZWPR870+LBZMPAs\n' +
			'mwedRN6urKpwqBItbKLtCpKC4UH9mR9cKYov/s5O7sp/U/0b0EDmWXbceeshwOCS\n' +
			'3mSkuOfA37YBE2DB8f6kXd6ceUjg8NseAXEy9UjkzMx+UYUWDfCK0tMhHoDlnBKl\n' +
			'Cljm3AA37P1UL8j0qwIDAQAB\n' +
			'-----END PUBLIC KEY-----',

		SECRET: 'iIUsWtNZcf'
	};

	public static STORAGE = {
		SELECTED_ADDRESS_DATA_ENC: 'RE4rfTr',
		SELECTED_ADDRESS_DATA: '@w3rW@sd',
		SELECTED_MERCHANT_SEARCH_DATA: '$324%wd',
		SELECTED_MERCHANT_REQUEST: 'Fr%3dft#',
		SELECTED_ORDER_RECEIPT: 'oReT4@C#',
		SELECTED_ORDER_GENIE_INIT: 'Fr$#dGGT',
		SHOW_MY_ORDERS: 'LoGtRDec',
		GO_TO_OTP_CONFIRMATION_PAGE: 'CfGtYE$5$',
		LOGIN_CONFIRMED_OTP: 'fGtr_er3dt',
	};

	public static ENDPOINTS = {
		loadPublicKey: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: true
			},
			url: '/iexpress/api/public_key',
			type: 'GET'
		},

		getLoggedInUser: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/getLoggedInUser',
			type: 'GET'
		},
		requestOTPCodeForLogin: {
			mockUrl: false,
			headerParam: {
				showLoading: true,
				showToast: true,
				excludeError: [401],
				skipAuth: true
			},
			url: '/iexpress/api/customer-web-public/requestOTPCodeForLogin',
			type: 'POST'
		},

		confirmOTPLogin: {
			mockUrl: false,
			headerParam: {
				showLoading: true,
				showToast: true,
				excludeError: [401],
				skipAuth: true
			},
			url: '/iexpress/api/customer-web-public/confirmOTPLogin',
			type: 'POST'
		},

		login: {
			mockUrl: false,
			headerParam: {
				showLoading: true,
				showToast: true,
				excludeError: [401],
				skipAuth: true
			},
			url: '/iexpress/customer-login-new',
			type: 'POST'
		},
		getSystemParams: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/system-params',
			type: 'GET'
		},

		expireUserCache: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/security/log-out-customer',
			type: 'POST'
		},

		clearUserCache: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: true
			},
			url: '/iexpress/api/security/clear-user-cache',
			type: 'POST'
		},

		refreshToken: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/getToken',
			type: 'POST'
		},

		getPagedRoles: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/role/getPagedRoles',
			type: 'POST'
		},

		getRoleUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/role/getRoleUpdateDTO',
			type: 'GET'
		},

		getSystemPrivileges: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/role/getSystemPrivileges',
			type: 'GET'
		},

		getRoles: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/role/getRoles',
			type: 'GET'
		},

		saveOrUpdateRole: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/role/saveOrUpdateRole',
			type: 'POST'
		},

		getPagedUsers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/user/getPagedUsers',
			type: 'POST'
		},

		getUserUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/user/getUserUpdateDTO',
			type: 'GET'
		},
		getMerchantUserUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/getMerchantUserUpdateDTO',
			type: 'GET'
		},
		getDriverUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getDriverUpdateDTO',
			type: 'GET'
		},

		saveOrUpdateUser: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/user/saveOrUpdateUser',
			type: 'POST'
		},

		getPagedPricingTemplates: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/delivery/getPagedPricingTemplates',
			type: 'POST'
		},
		saveOrUpdatePricingTemplate: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/delivery/saveOrUpdatePricingTemplate',
			type: 'POST'
		},
		getPricingTemplateUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			}, url: '/iexpress/api/delivery/getPricingTemplateUpdateDTO',
			type: 'GET'
		},

		getPagedDeliveryZones: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/delivery-zone/getPagedDeliveryZones',
			type: 'POST'
		},

		getDeliveryZoneUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/delivery-zone/getDeliveryZoneUpdateDTO',
			type: 'GET'
		},

		addDeliveryZone: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultAdded()),
				skipAuth: false
			},
			url: '/iexpress/api/delivery-zone/addDeliveryZone',
			type: 'POST'
		},

		updateDeliveryZone: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultUpdated()),
				skipAuth: false
			},
			url: '/iexpress/api/delivery-zone/updateDeliveryZone',
			type: 'POST'
		},

		getConstants: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getConstants',
			type: 'GET'
		},

		getBusinessTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getBusinessTypes',
			type: 'GET'
		},


		getDeliveryTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getDeliveryTypes',
			type: 'GET'
		},


		getPaymentTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getPaymentTypes',
			type: 'GET'
		},

		getApplicationProperties: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getApplicationProperties',
			type: 'GET'
		},

		updateUserPassword: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/updateMerchantUserPassword',
			type: 'POST'
		},
		updateMerchantUserPassword: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/user/updateUserPassword',
			type: 'POST'
		},

		getPagedMerchants: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getPagedMerchants',
			type: 'POST'
		},

		getPagedMerchantUsers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/getPagedMerchantUsers',
			type: 'POST'
		},

		getMerchantDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTO',
			type: 'GET'
		},

		getMerchantInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/getMerchantInfo',
			type: 'GET'
		},

		createBaseMerchant: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/createBaseMerchant',
			type: 'POST'
		},

		updateBaseMerchantInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateBaseMerchantInfo',
			type: 'POST'
		},
		updateMerchantUserInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/saveOrUpdateMerchantUser',
			type: 'POST'
		},
		saveDriverInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/saveOrUpdateDriver',
			type: 'POST'
		},
		saveDriverManagerInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/saveOrUpdateDriverManager',
			type: 'POST'
		},
		updateMerchantUser: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/updateMerchantUser',
			type: 'POST'
		},

		deleteMerchantUser: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchantUser/deleteMerchantUser',
			type: 'POST'
		},

		updateMerchantBusinessTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantBusinessTypes',
			type: 'POST'
		},

		updateMerchantLocation: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantLocation',
			type: 'POST'
		},

		updateMerchantAvailabilities: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantAvailabilities',
			type: 'POST'
		},

		getMerchantDTOWithPaymentAndDeliveryTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithPaymentAndDeliveryTypes',
			type: 'GET'
		},

		updatePaymentTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updatePaymentTypes',
			type: 'POST'
		},

		uploadImage: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false,
				isFileUpload: true
			},
			url: '/iexpress/api/storage/uploadImage',
			type: 'POST'
		},

		uploadImageCommon: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false,
				isFileUpload: true
			},
			url: '/iexpress/api/storage/uploadImage',
			type: 'POST'
		},

		updateMerchantImages: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantImages',
			type: 'POST'
		},

		updateDeliveryTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateDeliveryTypes',
			type: 'POST'
		},

		updateDeliveryZones: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateDeliveryZones',
			type: 'POST'
		},

		getMerchantDTOWithDeliveryZones: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithDeliveryZones',
			type: 'GET'
		},

		getMerchantDTOWithChargingTemplate: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithChargingTemplate',
			type: 'GET'
		},

		updateMerchantChargingTemplates: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantChargingTemplates',
			type: 'POST'
		},

		updateMerchantUsers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateMerchantUsers',
			type: 'POST'
		},

		getMerchantDTOWithOwners: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithOwners',
			type: 'GET'
		},

		getMerchantDTOWithBusinessTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithBusinessTypes',
			type: 'GET'
		},
		getPagedDrivers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getPagedDrivers',
			type: 'POST'
		},
		getPagedDriverManagers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getPagedDriverManagers',
			type: 'POST'
		},

		getPagedDriverGroups: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getPagedDriverGroups',
			type: 'POST'
		},

		updateDriverGroup: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/updateDriverGroups',
			type: 'POST'
		},

		getDrivergroupUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getDriverGroupsUpdateDTO',
			type: 'GET'
		},

		updateDriver: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/updateDriver',
			type: 'POST'
		},
		getAlreadyAddedUserIDs: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/driver/getAlreadyAddedUserIds',
			type: 'POST'
		},
		updateDriverManager: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/updateDriverManager',
			type: 'POST'
		},
		getMerchantDTOWithProductCategories: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantDTOWithProductCategories',
			type: 'GET'
		},

		saveOrUpdateProductCategory: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/saveOrUpdateProductCategory',
			type: 'POST'
		},

		updateProductCategoryOrders: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/updateProductCategoryOrders',
			type: 'POST'
		},
		getPagedCustomers: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/customer/getPagedCustomers',
			type: 'POST'
		},
		getCustomerUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer/getCustomerProfile',
			type: 'GET'
		},
		saveOrUpdateCustomer: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/customer/saveOrUpdateCustomer',
			type: 'POST'
		},

		getPagedMerchantProducts: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getPagedMerchantProducts',
			type: 'POST'
		},
		saveAndUpdateMerchantProduct: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/merchant/saveAndUpdateMerchantProduct',
			type: 'POST'
		},

		getProductsByCategory: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getProductsByCategory',
			type: 'GET'
		},

		getPagedProductOptions: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getPagedProductOptions',
			type: 'POST'
		},

		getMerchantProductOptionDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantProductOptionDTO',
			type: 'GET'
		},

		saveOrUpdateProductOption: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/merchant/saveOrUpdateProductOption',
			type: 'POST'
		},

		getPagedProductOptionTemplates: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getPagedProductOptionTemplates',
			type: 'POST'
		},

		getMerchantProductOptionTemplateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantProductOptionTemplateDTO',
			type: 'GET'
		},

		saveOrUpdateProductOptionTemplate: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/merchant/saveOrUpdateProductOptionTemplate',
			type: 'POST'
		},
		saveDriverGroupInfo: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/driver/saveOrUpdateDriverGroup',
			type: 'POST'
		},

		getMerchantProductDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantProductDTO',
			type: 'GET'
		},

		getPagedProductLists: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getPagedProductLists',
			type: 'POST'
		},

		getMerchantProductCategories: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantProductCategories',
			type: 'POST'
		},

		getMerchantProductList: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/merchant/getMerchantProductList',
			type: 'GET'
		},
		saveOrUpdateProductList: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/merchant/saveOrUpdateProductList',
			type: 'POST'
		},

		getMerchantAvailability: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/getMerchantAvailability',
			type: 'POST'
		},

		getSelectedMerchantDetails: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/getSelectedMerchantDetails',
			type: 'POST'
		},

		getSelectedMerchantProductDetails: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/getSelectedMerchantProductDetails',
			type: 'POST'
		},

		getChildOptionsOfChoices: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/getChildOptionsOfChoices',
			type: 'POST'
		},

		calculateDeliveryFee: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/calculateDeliveryFee',
			type: 'POST'
		},

		registerCustomerV2: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/registerCustomerV2',
			type: 'POST'
		},

		forgetCustomerPassword: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web-public/forgetCustomerPassword',
			type: 'POST'
		},

		checkoutOrder: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/checkoutOrder',
			type: 'POST'
		},

		placeOrderUsingGenie: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/placeOrderUsingGenie',
			type: 'POST'
		},

		placeOrderWhileAddNewCard: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/placeOrderWhileAddNewCard',
			type: 'POST'
		},

		getPagedOrders: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getPagedOrders',
			type: 'POST'
		},

		saveOrUpdateParentReferralCode: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/saveOrUpdateParentReferralCode',
			type: 'POST'
		},

		getCustomerReferralDetail: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getCustomerReferralDetail',
			type: 'GET'
		},

		getOrderDetailDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getOrderDetailDTO',
			type: 'GET'
		},
		reinstateVoucherAndPromocodes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/reinstateVoucherAndPromocodes',
			type: 'GET'
		},

		requestOTPCodeV2: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/requestOTPCodeV2',
			type: 'POST'
		},

		confirmOTP: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/confirmOTP',
			type: 'POST'
		},

		updateCustomerPassword: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/updateCustomerPassword',
			type: 'POST'
		},

		getOrderRemarks: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/order/getOrderRemarks',
			type: 'POST'
		},

		saveOrUpdateOrderRemarks: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/order/saveOrUpdateOrderRemarks',
			type: 'POST'
		},

		getOrderActions: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getOrderActions',
			type: 'POST'
		},

		getReviewComponents: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getReviewComponents',
			type: 'POST'
		},

		getOrderReview: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getOrderReview',
			type: 'POST'
		},

		reviewOrder: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/reviewOrder',
			type: 'POST'
		},

		saveOrUpdateCustomerV2: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/saveOrUpdateCustomerV2',
			type: 'POST'
		},

		transitOrderStatus: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/order/transitOrderStatus',
			type: 'POST'
		},

		cancelOrder: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
			},
			url: '/iexpress/api/order/cancelOrder',
			type: 'POST'
		},

		getSystemParameterUpdateDTO: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/system-parameter/getSystemParameterUpdateDTO',
			type: 'GET'
		},
		updateSystemParameter: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/system-parameter/updateSystemParameter',
			type: 'POST'
		},
		loadSystemParameterByKey: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/system-parameter/loadSystemParameterByKey',
			type: 'GET'
		},
		getPagedSystemParameters: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSearch()),
				skipAuth: false
			},
			url: '/iexpress/api/system-parameter/getPagedSystemParameters',
			type: 'POST'
		},
		getCustomerCardDetail: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getCustomerCardDetail',
			type: 'POST'
		},
		validatePromoCode: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false,
			},
			url: '/iexpress/api/customer-web/validatePromoCode',
			type: 'POST'
		},

		checkOnlinePaymentStatus: {
			headerParam: {
				showLoading: false,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/checkOnlinePaymentStatus',
			type: 'GET'
		},

		getCustomerVoucher: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getCustomerVoucher',
			type: 'POST'
		},

		genieSuccessUrl: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: true
			},
			url: '/iexpress/api/genieResponse/successFromWeb',
			type: 'GET'
		},

		genieFailedUrl: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: true
			},
			url: '/iexpress/api/genieResponse/failedFromWeb',
			type: 'GET'
		},

		getCustomerReferralPoint: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getCustomerReferralPoint',
			type: 'POST'
		},

		getRefPointVoucherEligibleAmounts: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getRefPointVoucherEligibleAmounts',
			type: 'POST'
		},

		createReferralPointVoucher: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/createReferralPointVoucher',
			type: 'POST'
		},

		getCustomerLocations: {
			headerParam: {
				showLoading: true,
				showToast: false,
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/getCustomerLocations',
			type: 'GET'
		},

		saveOrUpdateCustomerLocation: {
			headerParam: {
				showLoading: true,
				showToast: true,
				showMessage: (new ShowMessageDto().getDefaultSaveUpdate()),
				skipAuth: false
			},
			url: '/iexpress/api/customer-web/saveOrUpdateCustomerLocation',
			type: 'POST'
		},

		getAllSubBusinessTypes: {
			headerParam: {
				showLoading: true,
				showToast: true,
				skipAuth: true
			},
			url: '/iexpress/api/home/getSubBusinessTypes',
			type: 'GET'
		},
	};

}


