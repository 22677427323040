import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../setting/commons.settings';
import {AlertService} from '../service/common/alert.service';

@Injectable()
export class MerchantsGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_MERCHANT_SEARCH_DATA)
	selectedMerchantSearchData;

	constructor(private router: Router,
				private alertService: AlertService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.selectedMerchantSearchData) {
			this.alertService.showToaster('Please select your location', SETTINGS.TOASTER_MESSAGES.error);
			this.router.navigateByUrl('/home');
			return false;
		}
		return true;
	}

}
