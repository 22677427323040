// Angular
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {AuthNoticeComponent} from './auth-notice/auth-notice.component';
import {SharedModule} from '../../../shared/shared.module';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SignUpService} from './services/sign-up.service';
import {PwRecoverComponent} from './login/pw-recover/pw-recover.component';
import {ConfirmOtpComponent} from './confirm-otp/confirm-otp.component';
import {ConfirmOtpGuard} from '../../../main/guard/confirm-otp.guard';
import {SignUpGuard} from '../../../main/guard/sign-up.guard';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'otp-confirmation',
				component: ConfirmOtpComponent,
				canActivate: [ConfirmOtpGuard]
			},
			{
				path: 'sign-up',
				component: SignUpComponent,
				canActivate: [SignUpGuard]
			}
		]
	}
];


@NgModule({
	imports: [
		SharedModule,
		RouterModule.forChild(routes),
	],
	providers: [
		SignUpService
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		AuthNoticeComponent,
		SignUpComponent,
		PwRecoverComponent,
		ConfirmOtpComponent
	],
	entryComponents: [
		PwRecoverComponent
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule
		};
	}
}
