import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../../setting/commons.settings';
import {AlertService} from '../../service/common/alert.service';
import {UrlEncodeService} from '../../service/application/url-encode.service';

@Injectable()
export class GenieInitGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ORDER_GENIE_INIT)
	genieInitResponseJson;

	constructor(private router: Router,
				private urlEncodeService: UrlEncodeService,
				private alertService: AlertService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		// if (this.genieInitResponseJson) {
		// 	let json = JSON.parse(this.urlEncodeService.decode(this.genieInitResponseJson));
		// 	if (json.orderId) {
		// 		return true;
		// 	}
		// }
		//
		// this.alertService.showToaster('You are not authorized to access this url', SETTINGS.TOASTER_MESSAGES.warning);
		// this.router.navigateByUrl('/home');
		return true;
	}
}
