import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material';
import {OverlayModule} from '@angular/cdk/overlay';
import {InlineSVGModule} from 'ng-inline-svg';
import 'hammerjs';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './views/pages/auth/auth.module';
import {MainModule} from './main/main.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BaseComponent} from './core/eatts/base/base.component';
import {FooterComponent} from './core/eatts/footer/footer.component';
import {HeaderComponent} from './core/eatts/header/header.component';
import {SubheaderComponent} from './core/eatts/subheader/subheader.component';
import {ErrorPageComponent} from './core/eatts/content/error-page/error-page.component';
import {SharedModule} from './shared/shared.module';
import {NgxUiLoaderModule} from 'ngx-ui-loader';

@NgModule({
	declarations: [
		AppComponent,
		BaseComponent,
		FooterComponent,
		HeaderComponent,
		SubheaderComponent,
		ErrorPageComponent
	],
	imports: [
		BrowserModule,
		MainModule,
		SharedModule,
		AppRoutingModule,
		OverlayModule,
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		NgxUiLoaderModule
	],
	exports: [],
	providers: [
		{provide: LocationStrategy, useClass: HashLocationStrategy},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
