import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataService} from './service/data/data.service';
import {CommonService} from './service/common/common.service';
import {AlertService} from './service/common/alert.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApplicationService} from './service/application/application.service';
import {Ng2Webstorage} from 'ngx-webstorage';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {StorageService} from './service/data/storage.service';
import {DateService} from './service/application/date.service';
import {EncryptionService} from './service/application/encryption.service';
import {MasterDataService} from './service/data/master-data.service';
import {UrlEncodeService} from './service/application/url-encode.service';
import {DataResetService} from './service/application/data-reset.service';
import {SearchDataCacheService} from './service/common/search-data-cache.service';
import {CacheService} from './service/data/cache.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {GestureConfig} from '@angular/material';
import {HIGHLIGHT_OPTIONS, HighlightLanguage} from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import {LoginService} from './service/authentication/login.service';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store/index';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NxModule} from '@nrwl/nx';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {AuthEffects} from './store/auth/auth.effects';
import {AuthNoticeService} from './service/authentication/auth-notice.service';
import {TokenInterceptor} from './service/interceptors/token-interceptor';
import {KtDialogService} from './service/layout/kt-dialog.service';
import {LayoutConfigService} from './service/layout/layout-config.service';
import {LayoutRefService} from './service/layout/layout-ref.service';
import {MenuAsideService} from './service/layout/menu-aside.service';
import {MenuConfigService} from './service/layout/menu-config.service';
import {MenuHorizontalService} from './service/layout/menu-horizontal.service';
import {PageConfigService} from './service/layout/page-config.service';
import {SubheaderService} from './service/layout/subheader.service';
import {LayoutConfig} from './setting/_config/eatts/layout.config';
import {AuthGuard} from './guard/auth.guard';
import {HtmlClassService} from './service/layout/html-class.service';
import {ChangePasswordService} from './service/authentication/change-password.service';
import {ImageUploadService} from './service/common/image-upload.service';
import {MoneyService} from './service/common/money.service';
import {PrivilegeService} from './service/authentication/privilege.service';
import {OrderService} from './service/order/order.service';
import {MerchantsGuard} from './guard/merchants.guard';
import {ProductSelectionGuard} from './guard/product-selection.guard';
import {ProductSelectionHelperService} from './service/order/product-selection-helper.service';
import {CheckoutGuard} from './guard/checkout.guard';
import {ProductCheckoutHelperService} from './service/order/product-checkout-helper.service';
import {LoginGuard} from './guard/login.guard';
import {IsLoggedInGuard} from './guard/is-logged-in.guard';
import {AuthServiceConfig, FacebookLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {VerifiedNumberGuard} from './guard/verified-number.guard';
import {ReceiptGuard} from './guard/receipt.guard';
import {GenieInitGuard} from './guard/genie/genie-init.guard';
import {ConfirmOtpGuard} from './guard/confirm-otp.guard';
import {OtpService} from './service/authentication/otp.service';
import {SignUpGuard} from './guard/sign-up.guard';


// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{name: 'typescript', func: typescript},
		{name: 'scss', func: scss},
		{name: 'xml', func: xml},
		{name: 'json', func: json}
	];
}

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

let config = new AuthServiceConfig([
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider('477422979686907')
	}
]);

export function provideConfig() {
	return config;
}

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		Ng2Webstorage.forRoot({prefix: '', separator: '', caseSensitive: true}),
		HttpClientModule,
		NxModule.forRoot(),
		StoreModule.forRoot(reducers, {metaReducers}),
		EffectsModule.forRoot([
			AuthEffects
		]),
		StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
		StoreDevtoolsModule.instrument({maxAge: 50}),
		SocialLoginModule
	],
	providers: [
		DataService,
		CommonService,
		AlertService,
		ApplicationService,
		MomentDateAdapter,
		StorageService,
		DateService,
		EncryptionService,
		MasterDataService,
		UrlEncodeService,
		DataResetService,
		SearchDataCacheService,
		CacheService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},

		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {languages: hljsLanguages}
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig
		},
		LoginService,
		AuthNoticeService,
		KtDialogService,
		LayoutConfigService,
		LayoutRefService,
		MenuAsideService,
		MenuConfigService,
		MenuHorizontalService,
		PageConfigService,
		SubheaderService,
		AuthGuard,
		MerchantsGuard,
		ProductSelectionGuard,
		CheckoutGuard,
		LoginGuard,
		IsLoggedInGuard,
		VerifiedNumberGuard,
		ReceiptGuard,
		GenieInitGuard,
		ConfirmOtpGuard,
		SignUpGuard,
		HtmlClassService,
		ChangePasswordService,
		ImageUploadService,
		MoneyService,
		PrivilegeService,
		OrderService,
		ProductSelectionHelperService,
		ProductCheckoutHelperService,
		OtpService
	],
	declarations: [],
	exports: [
		BrowserAnimationsModule
	]
})
export class MainModule {
}
