import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';
import {SETTINGS} from '../../setting/commons.settings';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {Constants} from '../../setting/constants';
import {ApplicationService} from '../application/application.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	userTypeConst = Constants.userTypeConst;

	@LocalStorage(SETTINGS.ACCESS_TOKEN)
	private accessToken: any;

	@LocalStorage(SETTINGS.REFRESH_TOKEN)
	private refreshToken: any;

	isRedirectToCheckoutScreen: boolean;

	constructor(private dataService: DataService,
				private applicationService: ApplicationService) {
	}

	public doRequestOTP(payload: any): Observable<any> {
		const data = {...payload};
		return this.dataService.post(SETTINGS.ENDPOINTS.requestOTPCodeForLogin, data);
	}

	public confirmOTPLogin(payload: any): Observable<any> {
		const data = {...payload};
		return this.dataService.post(SETTINGS.ENDPOINTS.confirmOTPLogin, data);
	}

	public doLogin(payload: any): Observable<any> {
		const data = {...payload};
		delete data.userName;
		data.username = payload.userName;
		data.salesChannel = 'WEB';
		return this.dataService.post(SETTINGS.ENDPOINTS.login, data);
	}

	public onLogOut() {
		this.dataService.post(SETTINGS.ENDPOINTS.expireUserCache)
			.subscribe(data => {
			}, error => {
			});
		this.accessToken = null;
		this.refreshToken = null;
		this.applicationService.setLoggedInUser(null);
	}

	//TODO change the login check logic
	isUserLoggedIn() {
		return this.applicationService.hasLoggedInUser()
			&& this.accessToken
			&& this.refreshToken;
	}
}
