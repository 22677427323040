import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {MoneyService} from '../../../../../main/service/common/money.service';
import * as _ from 'underscore';
import {AppUtils} from '../../../../app.utils';
import {ConfirmationDialogComponent} from '../../../confirmation-dialog/confirmation-dialog.component';
import {CartItemSelectOptionComponent} from '../../../cart-item-select-option/cart-item-select-option.component';
import {OrderService} from '../../../../../main/service/order/order.service';
import {ProductSelectionHelperService} from '../../../../../main/service/order/product-selection-helper.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'kt-cart-item',
	templateUrl: './cart-item.component.html',
	styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit, OnDestroy {

	@Input('isFinalStep') isFinalStep;
	@Input('cartItem') cartItem;
	@Input('index') index;

	getSelectedItemsRequested = false;

	onSelectCartItemToEditSubs = new Subscription();

	constructor(
		private productSelectionHelperService: ProductSelectionHelperService,
		private dialog: MatDialog,
		private moneyService: MoneyService,
		private orderService: OrderService) {
	}

	ngOnInit() {
		this.onSelectCartItemToEditSubs = this.productSelectionHelperService
			.onSelectCartItemToEdit
			.subscribe((data: any) => {
				if (this.cartItem.product.productID == data.request.merchantProductID
					&& this.getSelectedItemsRequested) {
					this.getSelectedItemsRequested = false;

					this.onEditItemResponse(data.result);
				}
			});
	}

	ngOnDestroy(): void {
		this.onSelectCartItemToEditSubs.unsubscribe();
	}

	hasProductOption(productOptionID) {
		if (this.cartItem && this.cartItem.choices) {
			let addedProductOptionIDs = _.keys(this.cartItem.choices);
			productOptionID = productOptionID + '';
			return _.contains(addedProductOptionIDs, productOptionID)
				&& this.cartItem.choices[productOptionID].length > 0;
		}

		return false;
	}

	getItemTotalPrice() {
		return AppUtils.calculateCartItemPrice(this.cartItem, this.moneyService);
	}

	removeItem() {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent,
			{
				data: {
					title: 'Remove Cart Item',
					description: `<p>Are you sure to remove the item
					 <span class="bold-font">${this.cartItem.product.productName}</span> ?</p>`
				},
				width: '99%',
				maxWidth: '500px'

			}
		);

		const dialogSubs = dialogRef.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.orderService.removeCartItem(this.index);
					this.orderService.onOrderChange.next(true);
				}
				dialogSubs.unsubscribe();
			});
	}

	editItem() {
		this.getSelectedItemsRequested = true;
		this.productSelectionHelperService.getSelectCartItemToEdit({
			merchantProductID: this.cartItem.product.productID
		});
	}

	onEditItemResponse(optionData: any) {
		const dialogRef = this.dialog.open(CartItemSelectOptionComponent,
			{
				panelClass: 'custom-dialog-panel',
				width: '99%',
				maxWidth: '600px',
				data: {
					product: this.cartItem.product,
					optionData: optionData,
					cartItemIndex: this.index
				}
			}
		);

		const dialogSubs = dialogRef.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.orderService.updateCartItem(response, this.index);
					this.orderService.onOrderChange.next(true);
				}
				dialogSubs.unsubscribe();
			});
	}
}
