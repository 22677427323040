import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialImports} from './material.imports';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CapitalizeDirective} from './directives/capitalize.directive';
import {FocusInputDirective} from './directives/focus-input.directive';
import {GoBackDirective} from './directives/go-back.directive';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {InlineSVGModule} from 'ng-inline-svg';
import {ScrollTopDirective} from './directives/scroll-top.directive';
import {HeaderDirective} from './directives/header.directive';
import {OffcanvasDirective} from './directives/offcanvas.directive';
import {ToggleDirective} from './directives/toggle.directive';
import {MenuDirective} from './directives/menu.directive';
import {TabClickEventDirective} from './directives/tab-click-event.directive';
import {ContentAnimateDirective} from './directives/content-animate.directive';
import {StickyDirective} from './directives/sticky.directive';
import {TimeElapsedPipe} from './pipes/time-elapsed.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {GetObjectPipe} from './pipes/get-object.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {FirstLetterPipe} from './pipes/first-letter.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
	ContextMenu2Component,
	ContextMenuComponent,
	NotificationComponent,
	QuickActionComponent,
	ScrollTopComponent,
	SearchDefaultComponent,
	SearchDropdownComponent,
	SearchResultComponent,
	SplashScreenComponent,
	Subheader1Component,
	Subheader2Component,
	Subheader3Component,
	Subheader4Component,
	Subheader5Component,
	UserProfileComponent
} from './partials/layout';
import {NoticeComponent} from './partials/content/general/notice/notice.component';
import {
	ActionNotificationComponent,
	AlertComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from './partials/content/crud';
import {CartComponent} from './partials/layout/topbar/cart/cart.component';
import {ErrorComponent} from './partials/content/general/error/error.component';
import {RouterModule} from '@angular/router';
import {PortletComponent} from './partials/content/general/portlet/portlet.component';
import {PortletHeaderComponent} from './partials/content/general/portlet/portlet-header.component';
import {PortletBodyComponent} from './partials/content/general/portlet/portlet-body.component';
import {PortletFooterComponent} from './partials/content/general/portlet/portlet-footer.component';
import {AgmCoreModule} from '@agm/core';
import {FileDropModule} from 'ngx-file-drop';
import {DeleteEntityDialogComponent} from './partials/content/crud/delete-entity-dialog/delete-entity-dialog.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {DeliveryZoneMapComponent} from './components/delivery-zone-map/delivery-zone-map.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageCropperComponent} from './components/image-cropper/image-cropper.component';
import {ImageUploadSectionComponent} from './components/image-cropper/image-upload-section/image-upload-section.component';
import {ToMoneyPipe} from './pipes/to-money.pipe';
import {OrderCancellationConfirmationComponent} from './components/order-cancellation-confirmation/order-cancellation-confirmation.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {HasAnyPrivilegeDirective} from './directives/has-any-privilege.directive';
import {CartWrapperComponent} from './components/cart/cart-wrapper/cart-wrapper.component';
import {CartItemComponent} from './components/cart/cart-wrapper/cart-item/cart-item.component';
import {CartItemSelectOptionComponent} from './components/cart-item-select-option/cart-item-select-option.component';
import {ItemOptionComponent} from './components/cart-item-select-option/item-option/item-option.component';

@NgModule({
	declarations: [
		CapitalizeDirective,
		FocusInputDirective,
		GoBackDirective,
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		HasAnyPrivilegeDirective,
		ContentAnimateDirective,
		StickyDirective,
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		ToMoneyPipe,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		ContextMenu2Component,
		ContextMenuComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CartComponent,
		ErrorComponent,
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
		ConfirmationDialogComponent,
		DeliveryZoneMapComponent,
		ImageCropperComponent,
		ImageUploadSectionComponent,
		OrderCancellationConfirmationComponent,
		SafeUrlPipe,
		CartWrapperComponent,
		CartItemComponent,
		CartItemSelectOptionComponent,
		ItemOptionComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialImports,
		FlexLayoutModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		NgbModule,
		RouterModule,
		FileDropModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAJmt5N34DIItO1Avyk5FdF__nTuhzQc2A',
			libraries: ['places', 'drawing']
		}),
		ImageCropperModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialImports,
		FlexLayoutModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		NgbModule,
		RouterModule,
		AgmCoreModule,
		FileDropModule,
		CapitalizeDirective,
		FocusInputDirective,
		GoBackDirective,
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		ContentAnimateDirective,
		StickyDirective,
		HasAnyPrivilegeDirective,
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		ToMoneyPipe,
		SafeUrlPipe,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		ContextMenu2Component,
		ContextMenuComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CartComponent,
		ErrorComponent,
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
		ConfirmationDialogComponent,
		DeliveryZoneMapComponent,
		ImageCropperComponent,
		OrderCancellationConfirmationComponent,
		CartWrapperComponent,
		CartItemComponent,
		CartItemSelectOptionComponent,
		ItemOptionComponent
	],
	entryComponents: [
		ConfirmationDialogComponent,
		ImageUploadSectionComponent,
		OrderCancellationConfirmationComponent,
		CartItemSelectOptionComponent
	]
})
export class SharedModule {
}
