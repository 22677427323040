// Actions
import {AuthActions, AuthActionTypes} from './auth.actions';

export interface AuthState {
	loggedIn: boolean;
	authToken: string;
	refreshToken: string;
	user: any;
	isUserLoaded: boolean;
	isLoginError: boolean;
}

export const initialAuthState: AuthState = {
	loggedIn: false,
	authToken: undefined,
	refreshToken: undefined,
	user: undefined,
	isUserLoaded: false,
	isLoginError: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {

		case AuthActionTypes.Login: {
			const _token: string = action.payload.authToken;
			const _refreshToken: string = action.payload.refreshToken;
			const _user: any = action.payload.user;
			return {
				loggedIn: true,
				authToken: _token,
				refreshToken: _refreshToken,
				user: _user,
				isUserLoaded: false,
				isLoginError: false
			};
		}

		case AuthActionTypes.Logout:
			return initialAuthState;

		case AuthActionTypes.LoadLoggedInUserLoaded:
			return Object.assign({}, state, action.payload);

		case AuthActionTypes.LoginError:
			return Object.assign({}, state, action.payload);

		default:
			return state;
	}
}
