import {Injectable} from '@angular/core';
import {SETTINGS} from '../../setting/commons.settings';
import {DataService} from '../data/data.service';
import {LocalStorage} from 'ngx-webstorage';
import * as _ from 'underscore';
import {EncryptionService} from './encryption.service';

@Injectable()
export class ApplicationService {

	@LocalStorage(SETTINGS.LOGGED_USER_ENC)
	private loggedInUserEncrypted: string;

	@LocalStorage(SETTINGS.IS_FB_USER_ENC)
	private isFbUserEnc: string;

	private loggedInUserDecrypted: any;

	private isFbUser: any;

	@LocalStorage(SETTINGS.PUBLIC_KEY)
	private publicKey: any;

	constructor(private dataService: DataService,
				private encryptionService: EncryptionService) {
		if (!_.isEmpty(this.loggedInUserEncrypted)) {
			this.loggedInUserDecrypted = this.getDecryptedUser(this.loggedInUserEncrypted);
		} else {
			this.loggedInUserDecrypted = {};
		}

		if (!_.isEmpty(this.isFbUserEnc)) {
			this.isFbUser = (this.encryptionService.decrypt(this.isFbUserEnc) == 'Y');
		} else {
			this.isFbUser = false;
		}
	}

	loadPublicKey() {
		let loadPublicKeyObserving = this.dataService.get(SETTINGS.ENDPOINTS.loadPublicKey);
		loadPublicKeyObserving.subscribe((response: any) => {
			this.publicKey = response.publicKey;
		});
		return loadPublicKeyObserving;
	}

	setLoggedInUser(user) {
		if (user != null) {
			this.loggedInUserEncrypted = this.getEncryptedUser(user);
			this.loggedInUserDecrypted = this.getDecryptedUser(this.loggedInUserEncrypted);
		} else {
			this.loggedInUserEncrypted = '';
			this.loggedInUserDecrypted = {};
		}
	}

	setFbUser(isFb) {
		this.isFbUserEnc = this.encryptionService.encrypt(isFb);
		this.isFbUser = isFb == 'Y';
	}

	fbUserLoggedIn() {
		return this.isFbUser;
	}

	getUserDisplayName(): string {
		return this.loggedInUserDecrypted.displayName;
	}

	getLoggedInUserUserID() {
		return this.loggedInUserDecrypted.userID;
	}

	getLoggedInUserUserName() {
		return this.loggedInUserDecrypted.userName;
	}

	getLoggedInUser() {
		return this.loggedInUserDecrypted;
	}

	hasLoggedInUser() {
		return !_.isEmpty(this.loggedInUserDecrypted);
	}

	isUserMobileNoVerified() {
		return this.loggedInUserDecrypted.mobileNumberVerified;
	}

	private getEncryptedUser(user): string {
		return this.encryptionService.encrypt(JSON.stringify(user));
	}

	private getDecryptedUser(user) {
		return JSON.parse(this.encryptionService.decrypt(user));
	}
}
