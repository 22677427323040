import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material";
import {ImageUploadSectionComponent} from "./image-upload-section/image-upload-section.component";

@Component({
	selector: 'kt-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

	@Input('placeHolderImageFullPath') placeHolderImageFullPath;
	@Input('maintainAspectRatio') maintainAspectRatio;
	@Input('aspectRatio') aspectRatio;
	@Input('resizeToWidth') resizeToWidth;

	@Input('viewPortMaxWidth') viewPortMaxWidth;
	@Input('viewPortMaxHeight') viewPortMaxHeight;

	@Output('onImageCropped') onImageCropped = new EventEmitter();

	showImageUpload;

	constructor(public dialog: MatDialog) {
	}

	ngOnInit() {
	}

	onImageHover() {
		this.showImageUpload = true;
	}

	onImageLeave() {
		this.showImageUpload = false;
	}

	openImageUpload() {
		const dialogRef = this.dialog.open(ImageUploadSectionComponent, {
			width: '70%',
			panelClass: 'custom-dialog-panel',
			data: {
				maintainAspectRatio: this.maintainAspectRatio,
				aspectRatio: this.aspectRatio,
				resizeToWidth: this.resizeToWidth,
			}
		});

		const dialogSubs = dialogRef.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.onImageCropped.emit(response);
				}
				dialogSubs.unsubscribe();
			});
	}

	getSectionStyles() {
		return {
			'max-width': this.viewPortMaxWidth,
			'max-height': this.viewPortMaxHeight
		};
	}

	getUploadImageTextStyles() {
		return {
			'max-width': this.viewPortMaxWidth
		};
	}

	getProfileImageStyles() {
		return {
			'max-width': this.viewPortMaxWidth,
			'max-height': this.viewPortMaxHeight
		};
	}
}
