import {CustomerLocationDto} from './customer-location-dto';

export class Order {

	customerDetails: any = {
		customerName: '',
		customerAddress: '',
		customerID: null,
		customer: {}
	};
	deliveryType: number = null;
	deliveryTypeConst: string = '';
	businessType: number = null;
	baseSearchLocation: CustomerLocationDto;

	selectedMerchant : any = {};
	selectedMerchantDetails : any = {};

	deliveryFee : number = 0;
	cart: any = [];

	deliveryInfo: any = {};
	paymentInfo: any = {};

	customerCardDetails: any[] = [];
	selectedSavedCard = null;
	paymentTypeId: number;

	constructor() {
		this.baseSearchLocation = new CustomerLocationDto();
	}
}
