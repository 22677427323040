import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../setting/commons.settings';
import {ApplicationService} from '../service/application/application.service';
import {UrlEncodeService} from '../service/application/url-encode.service';

@Injectable()
export class SignUpGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	otpConfirmMobileNumber;

	@LocalStorage(SETTINGS.STORAGE.LOGIN_CONFIRMED_OTP)
	loginOTP;

	constructor(private applicationService: ApplicationService,
				private urlEncodeService: UrlEncodeService,
				private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let validMobileNumber = this.otpConfirmMobileNumber && this.urlEncodeService.decode(this.otpConfirmMobileNumber) != null;
		let validOTP = this.loginOTP && this.urlEncodeService.decode(this.loginOTP) != null;
		if (!validMobileNumber || !validOTP) {
			this.router.navigate([SETTINGS.PAGES.login]);
			return false;
		}
		return true;
	}

}
