import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FileUploadError} from "../../../dto/file-upload-error";
import {FileValidator} from "../../../validators/file.validator";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {SETTINGS} from "../../../../main/setting/commons.settings";
import {AlertService} from "../../../../main/service/common/alert.service";

@Component({
	selector: 'kt-image-upload-section',
	templateUrl: './image-upload-section.component.html',
	styleUrls: ['./image-upload-section.component.scss']
})
export class ImageUploadSectionComponent implements OnInit, OnDestroy {

	selectedFile: File = null;
	fileToUpload: File = null;

	fileUploadError: FileUploadError = new FileUploadError();

	isImageLoaded = false;
	imageChangedEvent: any = '';
	croppedImage: any = '';

	maintainAspectRatio;
	aspectRatio;
	resizeToWidth;

	constructor(public dialogRef: MatDialogRef<ImageUploadSectionComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private alertService: AlertService) {
		this.maintainAspectRatio = data['maintainAspectRatio'];
		this.aspectRatio = data['aspectRatio'];
		this.resizeToWidth = data['resizeToWidth'];
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
	}

	onCancelClick() {
		this.dialogRef.close(false);
	}

	isValidUpload() {
		return this.fileToUpload != null && !this.fileUploadError.hasError;
	}

	selectFile(event) {
		this.selectedFile = event.target.files[0];
		this.fileUploadError = FileValidator.isValidFile(this.selectedFile);

		if (!this.fileUploadError.hasError) {
			this.imageChangedEvent = event;
		} else {
			this.imageChangedEvent = '';
			this.isImageLoaded = false;
		}

	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.fileToUpload = new File([event.file], 'cropped-image.png');
	}

	imageLoaded() {
		this.isImageLoaded = true;
	}

	loadImageFailed() {
		this.isImageLoaded = false;
		this.alertService.showToaster("Image failed to load !", SETTINGS.TOASTER_MESSAGES.error);
	}

	onSave() {
		this.dialogRef.close(this.fileToUpload);
	}
}
