import {Constants} from "../../constants";

export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			'items': []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Orders',
					root: true,
					icon: 'flaticon2-cardiogram',
					page: '/dashboard',
					bullet: 'dot',
				},

				// {
				// 	title: 'Customer Order',
				// 	root: true,
				// 	icon: 'flaticon-price-tag',
				// 	page: '/customer-order',
				// 	bullet: 'dot',
				// },

				{section: 'Admin'},
				{
					title: 'Merchants',
					root: true,
					icon: 'flaticon2-shopping-cart-1',
					page: '/merchants',
					bullet: 'dot',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_MERCHANT_VIEW
				},
				{
					title: 'Customers',
					root: true,
					icon: 'flaticon-rotate',
					bullet: 'dot',
					page: '/customers',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_CUSTOMER_VIEW
				},
				{
					title: 'Drivers',
					root: true,
					icon: 'flaticon2-lorry',
					bullet: 'dot',
					page: '/drivers',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_DRIVER_VIEW + ',' + Constants.privilegeCodes.IEXPRESS_SETTINGS_DRIVER_GROUP_VIEW + ',' + Constants.privilegeCodes.IEXPRESS_SETTINGS_DRIVER_MANAGER_VIEW
				},
				{
					title: 'Delivery Zones',
					root: true,
					icon: 'flaticon-placeholder-3',
					bullet: 'dot',
					page: '/delivery-zones',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_DELIVERY_ZONE_VIEW
				},
				{
					title: 'Pricing Templates',
					root: true,
					icon: 'flaticon2-list',
					page: '/pricing-template',
					bullet: 'dot',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_PRICING_TEMPLATE_VIEW
				},
				{
					title: 'Merchant Users',
					root: true,
					icon: 'flaticon2-user',
					bullet: 'dot',
					page: '/merchant-users',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_MERCHANT_USER_VIEW
				},

				{
					title: 'Users',
					root: true,
					icon: 'flaticon-users-1',
					bullet: 'dot',
					page: '/users',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_USER_VIEW
				},
				{
					title: 'Roles',
					root: true,
					icon: 'flaticon-businesswoman',
					bullet: 'dot',
					page: '/roles',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_ROLE_VIEW
				},

				{
					title: 'Configurations',
					root: true,
					icon: 'flaticon-settings',
					bullet: 'dot',
					page: '/config',
					privileges: Constants.privilegeCodes.IEXPRESS_SETTINGS_CONFIGURATIONS_VIEW
				},

			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
