import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../setting/commons.settings';
import {AlertService} from '../service/common/alert.service';

@Injectable()
export class ProductSelectionGuard implements CanActivate {

	@LocalStorage(SETTINGS.STORAGE.SELECTED_MERCHANT_REQUEST)
	selectedMerchantRequest;

	constructor(private router: Router,
				private alertService: AlertService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.selectedMerchantRequest) {
			this.alertService.showToaster('Please select a restaurant', SETTINGS.TOASTER_MESSAGES.error);
			this.router.navigateByUrl('/restaurants');
			return false;
		}
		return true;
	}

}
