// Angular
import { Component } from '@angular/core';
import {ScrollTopOptions} from "../../../directives/scroll-top.directive";
// Layout


@Component({
	selector: 'kt-scroll-top',
	templateUrl: './scroll-top.component.html',
	styles : [`
		div {
			background: #D8007B;
		}
	`]
})
export class ScrollTopComponent {
	// Public properties
	scrollTopOptions: ScrollTopOptions = {
		offset: 300,
		speed: 600
	};
}
