import {Injectable} from '@angular/core';
import {Order} from '../../dto/order/order';
import {UrlEncodeService} from '../application/url-encode.service';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../../setting/commons.settings';
import * as _ from 'lodash';
import {CustomerLocationDto} from '../../dto/order/customer-location-dto';
import {DataService} from '../data/data.service';
import * as __ from 'underscore';
import {MoneyService} from '../common/money.service';
import {AppUtils} from '../../../shared/app.utils';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';
import {Constants} from '../../setting/constants';
import {CacheService} from '../data/cache.service';

@Injectable()
export class OrderService {

	onOrderChange = new BehaviorSubject(true);
	onOrderReset = new Subject();
	onCartReset = new Subject();

	@LocalStorage(SETTINGS.STORAGE.SELECTED_ADDRESS_DATA_ENC)
	selectedAddressDataEnc;

	order: Order;

	constructor(private urlEncodeService: UrlEncodeService,
				private dataService: DataService) {
		if (this.selectedAddressDataEnc != null) {
			try {
				this.order = JSON.parse(this.urlEncodeService.decode(this.selectedAddressDataEnc));
			} catch (e) {
				console.log('error on past json : ', e);
				this.resetOrder();
			}
		}
	}

	private updateOrder(newOrder: any) {
		this.order = newOrder;
		this.selectedAddressDataEnc = this.urlEncodeService.encode(JSON.stringify(this.order));
	}

	resetOrder() {
		this.updateOrder(new Order());
		this.onOrderReset.next(true);
	}

	resetCart() {
		let order = this.getOrder();
		order.cart = [];
		this.updateOrder(order);
		this.onCartReset.next(true);
	}

	getOrder(): Order {
		if (!this.order) {
			this.updateOrder(new Order());
		}
		return _.cloneDeep(this.order);
	}

	updateCustomerLocation(customerLocationDto: CustomerLocationDto) {
		let order = this.getOrder();
		order.baseSearchLocation = customerLocationDto;
		this.updateOrder(order);
	}

	getCustomerLocation(): CustomerLocationDto {
		return _.cloneDeep(this.order.baseSearchLocation);
	}

	updateDeliveryType(deliveryTypeID: number, deliveryTypeConst: string) {
		let order = this.getOrder();
		order.deliveryType = deliveryTypeID;
		order.deliveryTypeConst = deliveryTypeConst;
		this.updateOrder(order);
	}

	getDeliveryTypeID() {
		return _.cloneDeep(this.order.deliveryType);
	}

	updateBusinessTypeID(businessTypeID) {
		let order = this.getOrder();
		order.businessType = businessTypeID;
		this.updateOrder(order);
	}

	getBusinessTypeID() {
		return _.cloneDeep(this.order.businessType);
	}

	updateSelectedMerchant(selectedMerchant: any) {
		let order = this.getOrder();
		order.selectedMerchant = selectedMerchant;
		this.updateOrder(order);
	}

	getSelectedMerchant() {
		return _.cloneDeep(this.order.selectedMerchant);
	}

	setSelectedMerchantDetails(details: any) {
		let order = this.getOrder();
		order.selectedMerchantDetails = details;
		this.updateOrder(order);
	}

	getSelectedMerchantDetails() {
		return _.cloneDeep(this.order.selectedMerchantDetails);
	}

	setDeliveryFee(fee: number) {
		let order = this.getOrder();
		order.deliveryFee = fee;
		this.updateOrder(order);
	}

	getDeliveryFee() {
		return _.cloneDeep(this.order.deliveryFee);
	}

	isDeliveryFeeApplicable() {
		return this.order.deliveryTypeConst == Constants.deliveryTypeConst.DELIVERY;
	}

	setCart(cartItems: any) {
		let order = this.getOrder();
		order.cart = cartItems;
		this.updateOrder(order);
	}

	getCart() {
		return _.cloneDeep(this.order.cart);
	}

	getCartItemCount() {
		return this.getOrder().cart.length;
	}

	addCartItem(item: any) {
		let cart = this.getCart();
		cart.push(item);
		this.setCart(cart);
	}

	updateCartItem(item: any, index: number) {
		let array = this.getCart();
		array[index] = Object.assign({}, item);
		this.setCart(array);
	}

	removeCartItem(index: number) {
		let array = this.getCart();
		array.splice(index, 1);
		this.setCart(array);
	}

	getItemPrice(cartItem, moneyService: MoneyService) {
		return AppUtils.calculateCartItemPrice(cartItem, moneyService);
	}

	getTotalPriceWithoutTax(moneyService: MoneyService) {
		let total = 0;

		_.each(this.order.cart, (item: any) => {
			total += this.getItemPrice(item, moneyService);
		});

		return total;
	}

	getTotalPrice(totalAmountWithoutTax, moneyService: MoneyService) {
		let tax = 0;
		let serviceFee = 0;
		let deliveryFee = 0;
		let total;

		if (this.isTaxApplicable()) {
			tax = moneyService
				.gerPercentageAmount(totalAmountWithoutTax, this.getTaxRate());
		}

		if (this.isServiceFeeApplicable()) {
			serviceFee = moneyService
				.gerPercentageAmount(totalAmountWithoutTax, this.getServiceFeeRate());
		}

		if (this.isDeliveryFeeApplicable()) {
			deliveryFee = this.getDeliveryFee();
		}

		total = totalAmountWithoutTax;
		total = moneyService.add(deliveryFee, total);
		total = moneyService.add(tax, total);
		total = moneyService.add(serviceFee, total);
		return total;
	}

	isServiceFeeApplicable() {
		if (this.order.selectedMerchant) {
			return this.order.selectedMerchant.serviceFeePercentage > 0;
		}
		return false;
	}

	isTaxApplicable() {
		if (this.order.selectedMerchant) {
			return !this.order.selectedMerchant.taxIncluded
				&& this.order.selectedMerchant.taxPercentage > 0;
		}
		return false;
	}

	getTaxRate() {
		if (this.order.selectedMerchant) {
			return this.order.selectedMerchant.taxPercentage;
		}
		return 0;
	}

	getServiceFeeRate() {
		if (this.order.selectedMerchant) {
			return this.order.selectedMerchant.serviceFeePercentage;
		}
		return 0;
	}

	updateDeliveryInfo(deliveryInfo) {
		let order = this.getOrder();
		order.deliveryInfo = deliveryInfo;
		this.updateOrder(order);
	}

	updatePaymentTypeID(paymentTypeId) {
		let order = this.getOrder();
		order.paymentTypeId = paymentTypeId;
		this.updateOrder(order);
	}

	updateCustomerCardDetails(customerCardDetails) {
		let order = this.getOrder();
		order.customerCardDetails = customerCardDetails;
		this.updateOrder(order);
	}

	updateSelectedCardDetails(selectedSavedCard) {
		let order = this.getOrder();
		order.selectedSavedCard = selectedSavedCard;
		this.updateOrder(order);
	}

	hasFeaturedProduct(selectedMerchantDetails) {
		let hasFeaturedProduct = false;

		if (selectedMerchantDetails.products) {
			let prodArray = [];

			_.each(_.values(selectedMerchantDetails.products), (prods: any) => {
				prodArray = [...prodArray, ...prods];
			});

			for (let i = 0; prodArray.length > i; i++) {
				if (prodArray[i].featured) {
					hasFeaturedProduct = true;
					break;
				}
			}
		}

		return hasFeaturedProduct;
	}

	getAvailableCategoryListWithAll() {
		let list = [];
		let selectedMerchantDetails = this.getSelectedMerchantDetails();
		let featuredCategory = {
			'merchantProductCategoryID': -2,
			'categoryName': 'Featured',
			'description': null,
			'imageUrl': '',
			'displayOrder': 0.5
		};
		if (selectedMerchantDetails.categories) {
			if (selectedMerchantDetails.categories.length > 1) {
				list = [{
					'merchantProductCategoryID': -1,
					'categoryName': 'All',
					'description': null,
					'imageUrl': '',
					'displayOrder': 0
				}, ...selectedMerchantDetails.categories];

				if (this.hasFeaturedProduct(selectedMerchantDetails)) {
					list.splice(1, 0, featuredCategory);
				}
			} else {
				list = [...selectedMerchantDetails.categories];

				if (this.hasFeaturedProduct(selectedMerchantDetails)) {
					list.splice(0, 0, featuredCategory);
				}
			}
		}

		return list;
	}

	getCategoryWiseProducts() {
		let selectedMerchantDetails = this.getSelectedMerchantDetails();
		let data: any = {};
		if (selectedMerchantDetails.categories) {
			if (selectedMerchantDetails.categories.length > 1) {
				let allProductsArray = [];

				_.each(_.keys(selectedMerchantDetails.products), (key) => {
					allProductsArray = [...allProductsArray, ...selectedMerchantDetails.products[key]];
				});
				data = selectedMerchantDetails.products;

				allProductsArray = __.uniq(allProductsArray, (item: any) => {
					return item.productID;
				});

				data[-1] = allProductsArray;

			} else {
				data = selectedMerchantDetails.products;
			}

			if (this.hasFeaturedProduct(selectedMerchantDetails)) {
				let prodArray = [];
				data[-2] = [];

				_.each(_.values(selectedMerchantDetails.products), (prods: any) => {
					prodArray = [...prodArray, ...prods];
				});

				_.each(prodArray, (prod: any) => {
					if (prod.featured) {

						let find = _.find(data[-2], (item: any) => {
							return item.productID == prod.productID;
						});

						if (!find) {
							data[-2].push(prod);
						}
					}
				});
			}
		}

		return data;
	}

	getTotalPayableAmount(totalWithTax, voucherData, promoData, moneyService: MoneyService) {
		let orderTotalWithTax = totalWithTax;

		if (this.isPromoApplied(promoData)) {
			orderTotalWithTax = moneyService.subtract(orderTotalWithTax, promoData.deductedAmount);
		}

		if (this.isVoucherApplied(voucherData)) {
			_.each(voucherData.voucherWiseDeductions, (vu: any) => {
				orderTotalWithTax = moneyService.subtract(orderTotalWithTax, vu.deductedAmount);
			});
		}
		return orderTotalWithTax;
	}

	getOrderToPlace(
		user,
		moneyService: MoneyService,
		cacheService: CacheService,
		voucherData,
		promoData,
		paymentTypes: Array<any>,
		isNewCardAdd?) {
		let checkout: any = {};
		let isGeniePayment = false;
		let order = this.getOrder();

		checkout.merchantID = order.selectedMerchant.merchantID;
		checkout.customerID = user.userID;

		checkout.businessTypeID = order.businessType;
		checkout.deliveryTypeID = order.deliveryType;

		checkout.deliveryAddress = order.baseSearchLocation.address;

		checkout.deliveryLatitude = order.baseSearchLocation.lat;
		checkout.deliveryLongitude = order.baseSearchLocation.lng;

		checkout.deliveryFee = order.deliveryFee;

		let totalWithoutTax = this.getTotalPriceWithoutTax(moneyService);
		let totalPrice = this.getTotalPrice(totalWithoutTax, moneyService);

		let payableAmount = this.getTotalPayableAmount(totalPrice, voucherData, promoData, moneyService);

		if (this.isPromoApplied(promoData)) {
			checkout.grossTotalAmount = totalPrice;
			checkout.totalAmount = moneyService.subtract(totalPrice, promoData.deductedAmount);
			checkout.discountAmount = promoData.totalDeduction;
			checkout.discountCoupon = promoData.promoCodeDTO.promotionCode;
		} else {
			checkout.totalAmount = totalPrice;
		}

		if (this.isServiceFeeApplicable()) {
			checkout.serviceFee =
				moneyService.gerPercentageAmount(totalWithoutTax, this.getServiceFeeRate());
		}

		if (this.isTaxApplicable()) {
			checkout.totalTaxAmount = moneyService.gerPercentageAmount(totalWithoutTax, this.getTaxRate());
		}

		checkout.products = [];
		checkout.payments = [];

		_.each(order.cart, (item: any) => {
			let product = {
				merchantProductID: item.productID,
				quantity: item.noOfItems,
				remarks: item.instructions,
				options: []
			};

			_.each(_.keys(item.choices), (merchantProductOptionID: any) => {
				let productOption: any = {};
				productOption.merchantProductOptionID = merchantProductOptionID;
				productOption.choices = [];
				productOption.externalOption = this.isExternalProduct(item.productOptions, merchantProductOptionID);

				_.each(item.choices[merchantProductOptionID + ''], (choice: any) => {
					productOption.choices.push({
						merchantProductOptionChoiceID: choice.merchantProductOptionChoiceID,
						quantity: 1
					});
				});

				product.options.push(productOption);
			});
			checkout.products.push(product);
		});

		if (this.isVoucherApplied(voucherData)) {
			let voucherPaymentTypeID = cacheService.getData(Constants.masterDataKey.IE_PAYMENT_TYPES)
				.find((pt: any) => {
					return pt.paymentType == Constants.paymentTypesConst.VOUCHER;
				}).paymentTypeID;

			_.each(voucherData.voucherWiseDeductions, (vu: any) => {
				checkout.payments.push({
					paymentTypeID: voucherPaymentTypeID,
					paymentAmount: vu.deductedAmount,
					voucherID: vu.voucher.voucherID
				});
			});
		}

		if (payableAmount > 0) {
			let paymentType = _.find(paymentTypes, (p: any) => p.paymentTypeID === order.paymentTypeId);
			isGeniePayment = paymentType && paymentType.paymentType === Constants.paymentTypesConst.GENIE;

			checkout.payments.push({
				paymentTypeID: order.paymentTypeId,
				paymentAmount: payableAmount,
				saveNewCard: isNewCardAdd ? 'Y' : 'N',
				onlinePayment: isGeniePayment,
				selectedCardDetailDTO: order.selectedSavedCard
			});
		}

		checkout.instructions = order.deliveryInfo.instructions;
		checkout.salesChannel = 'WEB';

		checkout.isGeniePayment = isGeniePayment;
		return checkout;
	}

	isExternalProduct(productOptions, prodOptionID) {
		let find = productOptions.find((prodOption: any) => {
			return prodOption.productOptionID == prodOptionID;
		});

		if (find) {
			return find.isExternal;
		}
		return false;
	}

	isVoucherApplied(voucherData) {
		return !_.isEmpty(voucherData) && voucherData.totalDeduction != 0;
	}

	isPromoApplied(promoData) {
		return !_.isEmpty(promoData) && promoData.totalDeduction != 0;
	}
}
