import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LocalStorage} from "ngx-webstorage";
import {SETTINGS} from "../../setting/commons.settings";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	@LocalStorage(SETTINGS.ACCESS_TOKEN)
	private accessToken: any;

	@LocalStorage(SETTINGS.REFRESH_TOKEN)
	private refreshToken: any;

	constructor() {
	}

	//TODO handle token expiry
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let request;

		if (req.withCredentials) {
			request = req.clone({
				setHeaders: {
					'Authorization': 'Bearer ' + this.accessToken
				}
			});
		} else {
			request = req;
		}

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}));
	}
}
