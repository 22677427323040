import {Pipe, PipeTransform} from '@angular/core';
import {MoneyService} from "../../main/service/common/money.service";

@Pipe({
	name: 'toMoney'
})
export class ToMoneyPipe implements PipeTransform {

	constructor(private moneyService: MoneyService) {

	}

	transform(value: number, args?: any): any {
		return this.moneyService.getFormattedAmount(value);
	}

}
