import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
	selector: 'kt-delivery-zone-map-preview',
	templateUrl: './delivery-zone-map.component.html',
	styleUrls: ['./delivery-zone-map.component.scss']
})
export class DeliveryZoneMapComponent implements OnInit, OnChanges {

	@Input('coordinates') coordinates;
	@Input('zoom') zoom;
	@Input('height') height;

	options: any = {
		lat: 6.917366,
		lng: 79.864692,
		zoom: 14,
		draggable: false,
		editable: false,
		visible: true
	};

	constructor() {
	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['coordinates']) {
			this.options.lat = this.coordinates[0] ? this.coordinates[0].lat : 6.917366;
			this.options.lng = this.coordinates[0] ? this.coordinates[0].lng : 79.864692;
		}
	}

}
