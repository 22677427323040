import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailValidator} from '../../../../shared/validators/email.validator';
import {Subscription} from 'rxjs/Subscription';
import {AppUtils} from '../../../../shared/app.utils';
import {Router} from '@angular/router';
import {SETTINGS} from '../../../../main/setting/commons.settings';
import {SignUpService} from '../services/sign-up.service';
import {AlertService} from '../../../../main/service/common/alert.service';
import {AuthService} from 'angularx-social-login';
import {LocalStorage} from 'ngx-webstorage';
import {UrlEncodeService} from '../../../../main/service/application/url-encode.service';
import {select, Store} from '@ngrx/store';
import {isLoggedIn} from '../../../../main/store/auth/auth.selectors';
import {AppState} from '../../../../main/store/index';
import {LoginService} from '../../../../main/service/authentication/login.service';
import {LoginRequest} from '../../../../main/store/auth/auth.actions';
import * as CryptoJS from 'crypto-js';
import * as JSEncryptModule from 'jsencrypt';
import {Constants} from '../../../../main/setting/constants';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateService} from '../../../../main/service/application/date.service';

export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'DD/MM/YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'DD/MM/YYYY',
	},
};

@Component({
	selector: 'kt-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss'],
	providers: [
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
	]
})
export class SignUpComponent implements OnInit, OnDestroy {

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	otpConfirmMobileNumber;

	@LocalStorage(SETTINGS.STORAGE.LOGIN_CONFIRMED_OTP)
	loginOTP;

	mobileNumber: any = '';

	userForm: FormGroup;
	formErrors: any;

	userChangeFormSubs = new Subscription();
	onCustomerRegiterSubs = new Subscription();
	onLoginSubs = new Subscription();

	userTypeConst = Constants.userTypeConst;
	today;

	constructor(private signUpService: SignUpService,
				private formBuilder: FormBuilder,
				private alertService: AlertService,
				private authService: AuthService,
				private urlEncodeService: UrlEncodeService,
				private router: Router,
				private loginService: LoginService,
				private dateService: DateService,
				private store: Store<AppState>,) {
		this.formErrors = {
			name: {},
			email: {},
			dateOfBirthStr: {},
			parentReferralCode: {},
		};
	}

	ngOnInit() {
		this.today = this.dateService.getNow();
		this.mobileNumber = this.urlEncodeService.decode(this.otpConfirmMobileNumber);
		this.userForm = this.formBuilder.group({
			name: [null, [Validators.required, Validators.maxLength(250), Validators.pattern('^[a-zA-Z ]*$')]],
			email: [null, [Validators.maxLength(250), EmailValidator.validateEmail]],
			parentReferralCode: [null, [Validators.maxLength(100)]],
			dateOfBirthStr: ['']
		});

		this.userChangeFormSubs.unsubscribe();
		this.userChangeFormSubs = this.userForm.valueChanges.subscribe((form) => {
			this.formErrors = AppUtils.getFormErrors(this.userForm, this.formErrors);
		});

		this.onLoginSubs = this.store.pipe(
			select(isLoggedIn)
		).subscribe((isLoggedIn) => {
			if (isLoggedIn) {
				if (this.loginService.isRedirectToCheckoutScreen) {
					this.router.navigate([SETTINGS.PAGES.checkout]);
				} else {
					this.router.navigate([SETTINGS.PAGES.profile]);
				}
			}
		});

		this.onCustomerRegiterSubs = this.signUpService.onCustomerRegiter
			.subscribe(data => {
				this.doUserLogin(this.urlEncodeService.decode(this.loginOTP));
			});
	}

	ngOnDestroy(): void {
		this.userChangeFormSubs.unsubscribe();
		this.onCustomerRegiterSubs.unsubscribe();
		this.onLoginSubs.unsubscribe();
	}

	goToLogin() {
		this.router.navigate([SETTINGS.PAGES.profile]);
	}

	submit() {
		if (this.userForm.valid) {
			let form = AppUtils.trim(this.userForm.getRawValue());
			let data: any = {
				customerName: form.name,
				mobileNo: this.mobileNumber,
				email: form.email,
				parentReferralCode: form.parentReferralCode,
				password: this.urlEncodeService.decode(this.loginOTP),
				dateOfBirthStr: form.dateOfBirthStr ? this.dateService.getDefaultFormattedDate(form.dateOfBirthStr) : null,
			};

			this.signUpService.registerCustomer(AppUtils.trim(data));
		} else {
			this.alertService.showToaster('Please fill all required fields', SETTINGS.TOASTER_MESSAGES.error);
		}
	}

	doUserLogin(loginOTP) {
		const userName = this.mobileNumber.trim() + ':' + this.userTypeConst.CUSTOMER + ':' + 'webCustomer';
		const password = this.getEncryptedPassword(loginOTP);
		const customerName = null;
		const mobileNo = null;
		const email = null;
		const fbPassword = null;


		this.store.dispatch(new LoginRequest({
			userName,
			password,
			customerName,
			mobileNo,
			email,
			fbPassword,
		}));
	}

	private getEncryptedPassword(password) {

		password = CryptoJS.SHA1(password);
		password = CryptoJS.enc.Base64.stringify(password);

		let crypt = new JSEncryptModule.JSEncrypt();
		crypt.setPublicKey(SETTINGS.KEYS.PUBLIC_KEY);
		password = crypt.encrypt(password);
		return password;
	}

}
