import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {SETTINGS} from '../../../../main/setting/commons.settings';
import {UrlEncodeService} from '../../../../main/service/application/url-encode.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppUtils} from '../../../../shared/app.utils';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../main/store/index';
import {LoginRequest, MobileOTPConfirmation, OTPResendRequest} from '../../../../main/store/auth/auth.actions';
import {Constants} from '../../../../main/setting/constants';
import {OtpService} from '../../../../main/service/authentication/otp.service';
import * as CryptoJS from 'crypto-js';
import * as JSEncryptModule from 'jsencrypt';
import {Router} from '@angular/router';
import {isLoggedIn} from '../../../../main/store/auth/auth.selectors';
import {LoginService} from '../../../../main/service/authentication/login.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'kt-confirm-otp',
	templateUrl: './confirm-otp.component.html',
	styleUrls: ['./confirm-otp.component.scss']
})
export class ConfirmOtpComponent implements OnInit, OnDestroy {

	@LocalStorage(SETTINGS.STORAGE.GO_TO_OTP_CONFIRMATION_PAGE)
	otpConfirmMobileNumber;

	@LocalStorage(SETTINGS.STORAGE.LOGIN_CONFIRMED_OTP)
	loginOTP;

	mobileNumber: any = '';

	componentForm: FormGroup;
	formErrors: any;

	salesChannelConst = Constants.salesChannelConst;
	userTypeConst = Constants.userTypeConst;

	onLoginSubs = new Subscription();
	onOTPConfirmationSuccessSubs = new Subscription();

	constructor(private urlEncodeService: UrlEncodeService,
				private formBuilder: FormBuilder,
				private otpService: OtpService,
				private router: Router,
				private loginService: LoginService,
				private store: Store<AppState>,) {

		this.formErrors = {
			verificationCode: {}
		};
	}

	ngOnInit() {
		this.loginOTP = null;
		this.mobileNumber = this.urlEncodeService.decode(this.otpConfirmMobileNumber);

		this.componentForm = this.formBuilder.group({
			verificationCode: ['', [Validators.required]],
		});

		this.onLoginSubs = this.store.pipe(
			select(isLoggedIn)
		).subscribe((isLoggedIn) => {
			if (isLoggedIn) {
				if (this.loginService.isRedirectToCheckoutScreen) {
					this.router.navigate([SETTINGS.PAGES.checkout]);
				} else {
					this.router.navigate([SETTINGS.PAGES.home]);
				}
			}
		});

		this.onOTPConfirmationSuccessSubs = this.otpService.onOTPConfirmationSuccess
			.subscribe((data: any) => {
				this.loginOTP = this.urlEncodeService.encode(data.otp);
				if (data.customerExist) {
					this.doUserLogin(data.otp);
				} else {
					this.router.navigate([SETTINGS.PAGES.signUp]);
				}
			});
	}

	ngOnDestroy(): void {
		this.onLoginSubs.unsubscribe();
		this.onOTPConfirmationSuccessSubs.unsubscribe();
	}

	onReSend() {
		this.store.dispatch(new OTPResendRequest({
			mobileNumber: this.mobileNumber.trim(),
			salesChannel: this.salesChannelConst.WEB,
		}));
	}

	submit() {
		const controls = this.componentForm.controls;
		/** check form */
		if (this.componentForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.formErrors = AppUtils.getFormErrors(this.componentForm, this.formErrors);
			return;
		}

		this.store.dispatch(new MobileOTPConfirmation({
			mobileNumber: this.mobileNumber,
			salesChannel: this.salesChannelConst.WEB,
			otpCode: controls['verificationCode'].value.trim()
		}));
	}

	doUserLogin(loginOTP) {
		const userName = this.mobileNumber.trim() + ':' + this.userTypeConst.CUSTOMER + ':' + 'webCustomer';
		const password = this.getEncryptedPassword(loginOTP);
		const customerName = null;
		const mobileNo = null;
		const email = null;
		const fbPassword = null;


		this.store.dispatch(new LoginRequest({
			userName,
			password,
			customerName,
			mobileNo,
			email,
			fbPassword,
		}));
	}

	private getEncryptedPassword(password) {

		password = CryptoJS.SHA1(password);
		password = CryptoJS.enc.Base64.stringify(password);

		let crypt = new JSEncryptModule.JSEncrypt();
		crypt.setPublicKey(SETTINGS.KEYS.PUBLIC_KEY);
		password = crypt.encrypt(password);
		return password;
	}

}
