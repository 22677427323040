// Angular
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
// RxJS
import {Subscription} from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
import {LayoutConfig} from '../../../main/setting/_config/eatts/layout.config';
import {MenuConfig} from '../../../main/setting/_config/eatts/menu.config';
import {PageConfig} from '../../../main/setting/_config/eatts/page.config';
import {LayoutConfigService} from '../../../main/service/layout/layout-config.service';
import {MenuConfigService} from '../../../main/service/layout/menu-config.service';
import {PageConfigService} from '../../../main/service/layout/page-config.service';
import {Router} from '@angular/router';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public constructor
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;

	// Private properties
	private unsubscribe: Subscription[] = [];

	constructor(private layoutConfigService: LayoutConfigService,
				private menuConfigService: MenuConfigService,
				private pageConfigService: PageConfigService,
				private router: Router) {


		// register configs by demos
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		this.menuConfigService.loadConfigs(new MenuConfig().configs);
		this.pageConfigService.loadConfigs(new PageConfig().configs);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	isHeaderVisible(): boolean {
		// Check if the current route is not equal to '/eatts'
		return this.router.url !== '/eatt';
	}

	isFooterVisible(): boolean {
		// Check if the current route is not equal to '/eatts'
		return this.router.url !== '/eatt';
	}
}
