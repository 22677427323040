import {Action} from '@ngrx/store';
import {AuthState} from './auth.reducers';

export enum AuthActionTypes {
	Login = '[Login] Action',
	LoginRequest = '[Login] Action Requested',
	Logout = '[Logout] Action',
	LoadLoggedInUserLoaded = '[Loaded] Logged In User Loaded',
	LoginError = '[Login] Error',
	MobileNumberLoginRequest = '[Login] Mobile Number Login Action',
	MobileNumberLoginRequestSuccess = '[Login] Mobile Number Login Action Success',
	MobileOTPConfirmation = '[Login] Mobile OTP Confirmation Action',
	MobileOTPConfirmationSuccess = '[Login] Mobile OTP Confirmation Action Success',
	OTPResendRequest = '[Login] Mobile Number OTP Resend Action',
	OTPResendRequestSuccess = '[Login] Mobile Number OTP Resend Action Success',

}

export class MobileNumberLoginRequest implements Action {
	readonly type = AuthActionTypes.MobileNumberLoginRequest;

	constructor(public payload: {
		mobileNumber: string,
		salesChannel: string,
	}) {
	}
}

export class OTPResendRequest implements Action {
	readonly type = AuthActionTypes.OTPResendRequest;

	constructor(public payload: {
		mobileNumber: string,
		salesChannel: string,
	}) {
	}
}

export class MobileNumberLoginRequestSuccess implements Action {
	readonly type = AuthActionTypes.MobileNumberLoginRequestSuccess;

	constructor() {
	}
}

export class OTPResendRequestSuccess implements Action {
	readonly type = AuthActionTypes.OTPResendRequestSuccess;

	constructor() {
	}
}

export class MobileOTPConfirmation implements Action {
	readonly type = AuthActionTypes.MobileOTPConfirmation;

	constructor(public payload: {
		mobileNumber: string,
		otpCode: string,
		salesChannel: string,
	}) {
	}
}

export class MobileOTPConfirmationSuccess implements Action {
	readonly type = AuthActionTypes.MobileOTPConfirmationSuccess;

	constructor() {
	}
}

export class LoginRequest implements Action {
	readonly type = AuthActionTypes.LoginRequest;

	constructor(public payload: {
		userName: string,
		password: string,
		customerName: string,
		mobileNo: string,
		email: string,
		fbPassword: string,
	}) {
	}
}

export class Login implements Action {
	readonly type = AuthActionTypes.Login;

	constructor(public payload: { authToken: string, refreshToken: string, user: any }) {
	}
}

export class Logout implements Action {
	readonly type = AuthActionTypes.Logout;
}

export class LoadLoggedInUserLoaded implements Action {
	readonly type = AuthActionTypes.LoadLoggedInUserLoaded;

	constructor(public payload: AuthState) {
	}
}

export class LoginError implements Action {
	readonly type = AuthActionTypes.LoginError;

	constructor(public payload: { isLoginError: boolean }) {
	}
}

export type AuthActions = LoginRequest
	| Login
	| Logout
	| LoadLoggedInUserLoaded
	| LoginError
	| MobileNumberLoginRequest
	| MobileNumberLoginRequestSuccess
	| MobileOTPConfirmation
	| MobileOTPConfirmationSuccess
	| OTPResendRequest
	| OTPResendRequestSuccess;
