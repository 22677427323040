import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';
import {SETTINGS} from "../../setting/commons.settings";
import {Subject} from "rxjs/Subject";

@Injectable()
export class ChangePasswordService {

  onUserPasswordReset = new Subject();
  onUserPasswordChange = new Subject();
  onCustomerPasswordReset = new Subject();
  onCustomerPasswordChange = new Subject();

  constructor(private dataService: DataService) {
  }

  resetUserPassword(data) {
    return new Promise((resolve, reject) => {
      this.dataService.post(SETTINGS.ENDPOINTS.updateUserPassword, data)
        .subscribe((response: any) => {
          this.onUserPasswordReset.next(response);
          resolve(response);
        }, reject)
    });
  }

  changeUserPassword(data) {
    this.dataService.post(SETTINGS.ENDPOINTS.updateUserPassword, data)
      .subscribe((response: any) => {
        this.onUserPasswordChange.next(response);
      });
  }

	resetMerchantUserPassword(data) {
		return new Promise((resolve, reject) => {
			this.dataService.post(SETTINGS.ENDPOINTS.updateMerchantUserPassword, data)
				.subscribe((response: any) => {
					this.onUserPasswordReset.next(response);
					resolve(response);
				}, reject)
		});
	}

	changeMerchatUserPassword(data) {
		this.dataService.post(SETTINGS.ENDPOINTS.updateMerchantUserPassword, data)
			.subscribe((response: any) => {
				this.onUserPasswordChange.next(response);
			});
	}

  resetCustomerPassword(data) {
		return new Promise((resolve, reject) => {
			this.dataService.post(SETTINGS.ENDPOINTS.updateCustomerPassword, data)
				.subscribe((response: any) => {
					this.onCustomerPasswordReset.next(response);
					resolve(response);
				}, reject)
		});
  }

  changeCustomerPassword(data) {
		this.dataService.post(SETTINGS.ENDPOINTS.updateCustomerPassword, data)
			.subscribe((response: any) => {
				this.onCustomerPasswordChange.next(response);
			});
  }


}
